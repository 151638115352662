<template>
    <div class="PersonNav bg-white border-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <ul class="nav nav-pills HorizontalNavPills HorizontalNavPills--Bordered justify-content-start">
                        <li class="nav-item">
                            <router-link tag="a"
                                         class="text-sm-center nav-link"
                                         :class="{ 'active': isViewActive('timeline') }"
                                         :to="{ query: { view: 'timeline' } }">
                                <i class="fas fa-stream"></i>
                                <span class="ml-1 d-none d-lg-inline-block">{{ $t('Timeline') }}</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link tag="a"
                                         class="text-sm-center nav-link"
                                         :class="{ 'active': isViewActive('details') }"
                                         :to="{ query: { view: 'details' } }">
                                <i class="fas fa-sitemap"></i>
                                <span class="ml-1 d-none d-lg-inline-block">{{ $t('Details') }}</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link tag="a"
                                         class="text-sm-center nav-link"
                                         :class="{ 'active': isViewActive('life-events') }"
                                         :to="{ query: { view: 'life-events' } }">
                                <i class="far fa-flag"></i>
                                <span class="ml-1 d-none d-lg-inline-block">{{ $t('Life Events') }}</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link tag="a"
                                         class="text-sm-center nav-link"
                                         :class="{ 'active': isViewActive('communication-subscriptions') }"
                                         :to="{ query: { view: 'communication-subscriptions' } }">
                                <i class="fas fa-map-signs"></i>
                                <span class="ml-1 d-none d-lg-inline-block">{{ $t('Communication Subscriptions') }}</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link tag="a"
                                         class="text-sm-center nav-link"
                                         :to="{ name: 'person-edit', params: { id: personId } }">
                                <i class="fas fa-user-edit"></i>
                                <span class="ml-1 d-none d-lg-inline-block">{{ $t('common_action_edit') }}</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * Person navigation (menu).
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 June 2020
 * @lastModifiedAt 15 November 2020
 */
export default {
    name: 'PersonNav',
    data () {
        return {
            activeView: null
        };
    },
    computed: {
        ...mapGetters('person', {
            person: 'person'
        }),
        personId () {
            return this.person.id;
        }
    },
    beforeMount () {
        // The listen to changes and use mutations to modify property.
        this.$watch('$route', {
            handler: function (to) {
                let view = to.query.view;
                if (view === undefined || view === null) view = 'timeline';
                this.activeView = view;
            },
            immediate: true
        });
    },
    methods: {
        isViewActive (view) {
            return view === this.activeView;
        }
    }
};
</script>
