<template>
    <ul class="PersonLifeEventsTimeline">
        <slot></slot>
    </ul>
</template>

<script>
/**
 * Person life event timeline item (wrapper and style enabler, not listing).
 *
 * @see PersonLifeEventsTimelineItem.vue
 *
 * @author Dimitris Gkoulis
 * @createdAt 15 November 2020
 */
export default {
    name: 'PersonLifeEventsTimeline'
};
</script>

<style lang="scss">
    $ple--timeline-spacing: 50px !default;
    $ple--timeline-line-dot-size: 11px !default; // The left line size
    $ple--timeline-line-size: 4px !default; // The left line size
    $ple--timeline-border-color: #1070ca !default;
    $ple--timeline-dot-border-size: 4px !default;
    $ple--timeline-border-radius: 4px !default; // bottom right, top right radius.

    .PersonLifeEventsTimeline {
        position: relative;
        margin-left: 10px;
        border-left: $ple--timeline-line-size solid $ple--timeline-border-color;
        border-bottom-right-radius: $ple--timeline-border-radius;
        border-top-right-radius: $ple--timeline-border-radius;
        /* width: calc(100% - 235px); Uncomment to display properly the date (left) */
        width: calc(100% - 10px);
        padding: $ple--timeline-spacing;
        background: #ffffff;
        list-style: none;

        .PersonLifeEventsTimelineItem {
            position: relative;
            border-bottom: 2px dashed #dcdcdc;
            padding-bottom: ($ple--timeline-spacing * 0.5);
            margin-bottom: $ple--timeline-spacing;

            &:last-of-type {
                padding-bottom: 0;
                margin-bottom: 0;
                border: none;
            }
            // &:before,
            &:after {
                position: absolute;
                display: block;
                top: 0;
            }
            /*
            &:before {
                content: attr(data-date);
                top: 12px;
                left: -235px;
                width: 160px;
                font-size: 0.95em;
                font-weight: 700;
                text-align: right;
                color: #868686;
            }
            */
            &:after {
                content: "";
                top: 18px;
                left: ($ple--timeline-spacing + $ple--timeline-line-size + ($ple--timeline-line-dot-size * 0.35)) * -1;
                height: $ple--timeline-line-dot-size;
                width: $ple--timeline-line-dot-size;
                background: #ffffff;
                border-radius: 50%;
                box-shadow: 0 0 0 $ple--timeline-dot-border-size fade-in($ple--timeline-border-color, 1%);
            }

            &--Icon--Fa {

                &:after {
                    //noinspection CssNoGenericFontName
                    font-family: "Font Awesome 5 Free";
                    content: "\f024"; // Default.
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    font-weight: 900;
                    color: #1070ca;
                    font-size: 14px;
                    box-shadow: 0 0 0 3px #1070ca;
                    top: 12px;
                    left: -65px;
                    height: 26px;
                    width: 26px;
                }
                &--CUSTOM:after {
                    content: "\f024" !important;
                }
                &--WORK:after {
                    content: "\f0b1";
                }
                &--EDUCATION:after {
                    content: "\f19d";
                }
                &--RELATIONSHIP:after {
                    content: "\f004";
                }
                &--HOME_AND_LIVING:after {
                    content: "\f015";
                }
                &--FAMILY:after {
                    content: "\f0c0";
                }
                &--TRAVEL:after {
                    content: "\f558";
                }
                &--INTERESTS_AND_ACTIVITIES:after {
                    content: "\f091";
                }
                &--WELLNESS_AND_HEALTH:after {
                    content: "\f5bb";
                }
                &--MILESTONES_AND_ACHIEVEMENTS:after {
                    content: "\f13d";
                }
                &--REMEMBRANCE:after {
                    content: "\f1fd";
                }
            }
        }

        .PersonLifeEventsTimelineItem__Actions {
            position: absolute;
            top: 0;
            right: 0;

            i {
                color: #bfbfbf !important;
            }
        }
    }
</style>
