/**
 * Provides common functionality for organizing activities editors.
 * e.g.: Note editor, Call Log editor, e.t.c.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 27 March 2020
 */
export default {
    name: 'AsiEditorMixin',
    data () {
        return {
            displayEditor: false
        };
    },
    methods: {
        onEditorClose () {
            this.displayEditor = false;
        },
        onOrganizingAsiUpdateSuccess ($event) {
            this.displayEditor = false;
            this.$emit('organizing-asi-update-success', $event);
        },
        onOrganizingAsiDeleteSuccess ($event) {
            this.displayEditor = false;
            this.$emit('organizing-asi-delete-success', $event);
        }
    }
};
