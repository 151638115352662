<template>
    <activity-stream-item-structure :activity="activity" :with-body-below="true">
        <p class="mb-0" slot="description" v-html="sentenceValue"></p>
        <div slot="bodyBelow">
            <div class="asi-table-container">
                <table class="table table-sm">
                    <tbody>
                    <tr v-if="displayBaseAndExplanation">
                        <td>{{ $t('Lawful Basis') }}</td>
                        <td>{{ $t(activity.specific.gdprLawfulBasis) }}</td>
                        <!-- Prefer raw value (it will be very helpful for advanced users. Normal user will rarely click "see more"). -->
                    </tr>
                    <tr>
                        <td>{{ $t('Type') }}</td>
                        <td>{{ $t(activity.specific.type) }}</td>
                        <!-- Prefer raw value (it will be very helpful for advanced users. Normal user will rarely click "see more"). -->
                    </tr>
                    <tr>
                        <td>{{ $t('Status') }}</td>
                        <td>{{ $t(activity.specific.status) }}</td>
                        <!-- Prefer raw value (it will be very helpful for advanced users. Normal user will rarely click "see more"). -->
                    </tr>
                    <tr v-if="displayBaseAndExplanation">
                        <td>{{ $t('Explanation') }}</td>
                        <td>{{ activity.specific.explanation }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('Timestamp') }}</td>
                        <td>{{ activity.specific.timestamp | dateLiteral }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('Requested by User') }}</td>
                        <td>{{ activity.data.userLogin }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </activity-stream-item-structure>
</template>

<script>
import ActivityStreamItemStructure from './activity-stream-item-structure';

/**
 * Activity Stream Item for type COMMUNICATION_SUBSCRIPTION.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 27 March 2020
 */
export default {
    name: 'AsiCommunicationSubscription',
    props: ['activity'],
    components: {
        ActivityStreamItemStructure
    },
    data () {
        return {
            displayBaseAndExplanation: false,
            sentenceValue: null
        };
    },
    mounted () {
        this.buildSentenceValue();
    },
    methods: {
        buildSentenceValue () {
            let identityProfile = this.activity.data.identityProfileName;
            let status = 'sentence_status_' + this.activity.specific.status; // OPT_IN, OPT_OUT, etc.
            let type = 'sentence_type_' + this.activity.specific.type;
            if (this.activity.specific.status === 'OPTED_IN') this.displayBaseAndExplanation = true;

            this.sentenceValue = this.$t('sentence', {
                identityProfile: identityProfile,
                status: this.$t(status),
                type: this.$t(type)
            });
        }
    }
};
</script>

<i18n>
{
    "en": {
        "GDPR Lawful Basis": "GDPR Lawful Basis",
        "Lawful Basis": "Lawful Basis",
        "Type": "Type",
        "Status": "Status",
        "Explanation": "Explanation",
        "Timestamp": "Timestamp",
        "Requested by User": "Requested by User",

        "sentence_status_OPTED_IN": "subscribed to the",
        "sentence_status_OPTED_OUT": "unsubscribed from the",
        "sentence_status_NOT_OPTED_IN_OR_OUT": "unsubscribed (via reset) from the",

        "sentence_type_MARKETING_SMS": "Marketing SMS",
        "sentence_type_ONE_TO_ONE_SMS": "One To One SMS",
        "sentence_type_MARKETING_CALL": "Marketing Call",
        "sentence_type_ONE_TO_ONE_CALL": "One To One Call",
        "sentence_type_MARKETING_EMAIL": "Marketing Email",
        "sentence_type_ONE_TO_ONE_EMAIL": "One To One Email",

        "sentence": "<b>{identityProfile}</b> {status} <i>{type}</i> communication subscription."
    },
    "el": {
        "GDPR Lawful Basis": "Γ.Κ.Π.Δ. Νομική Βάση",
        "Lawful Basis": "Νομική Βάση",
        "Type": "Τύπος",
        "Status": "Κατάσταση",
        "Explanation": "Περιγραφή",
        "Timestamp": "Ημερομηνία και ώρα",
        "Requested by User": "Αιτήθηκε από το Χρήστη",

        "sentence_status_OPTED_IN": "εγγράφηκε στη",
        "sentence_status_OPTED_OUT": "απεγγράφηκε από τη",
        "sentence_status_NOT_OPTED_IN_OR_OUT": "απεγγράφηκε (μέσω επαναφοράς) από τη",

        "sentence_type_MARKETING_SMS": "Προωθητικά SMS",
        "sentence_type_ONE_TO_ONE_SMS": "Προσωπικά SMS",
        "sentence_type_MARKETING_CALL": "Προωθητικές Κλήσεις",
        "sentence_type_ONE_TO_ONE_CALL": "Προσωπικές Κλήσεις",
        "sentence_type_MARKETING_EMAIL": "Προωθητικά Email",
        "sentence_type_ONE_TO_ONE_EMAIL": "Προσωπικά Email",

        "sentence": "<b>{identityProfile}</b> {status} συνδρομή επικοινωνίας <i>{type}</i>."
    }
}
</i18n>
