<template>
    <div class="activity-stream-item-resolver" v-if="childComponent">
        <component v-bind:is="childComponent"
                   :key="childComponentKey"
                   :activity="activity"
                   v-on:update-key="onUpdateKey"
                   v-on:organizing-asi-update-success="onOrganizingAsiUpdateSuccess"
                   v-on:organizing-asi-delete-success="onOrganizingAsiDeleteSuccess"></component>
    </div>
</template>

<script>
import ResolverHelper from './activity-stream-item-resolver-helper';

/**
 * Activity Stream Item Resolver : given an Activity it renders the corresponding component.
 *
 * This components does not reacts on Activity changes.
 * Once the component is mounted is does not change.
 * You can force the component to change by updating the :key attribute.
 *
 * Children (components) are process their data and HTML on mount or before mount.
 * And that's why they are not reactive. Once they are mounted, they don't change.
 *
 * Why? PERFORMANCE of course. Activity objects are big and ugly.
 * We should avoid computed properties.
 * They need special treatment and all properties should be processed once.
 *
 * How to make components reactive:
 * - add :key="activity.id + SOMETHING_RANDOM_ON_EACH_CALL" to PARENT COMPONENT
 *   Why? Because when an activity is changed, the ID doesn't and the components does not being re-mounted.
 *        BUT, the activity prop itself changes
 * - add :key="activity.id + SOMETHING_THAT_CHANGES_WHEN_WE_TELL_IT_TO" to PARENT COMPONENT
 *   The same with previous but we have more control over when a component should be remounted
 *
 * In a more advanced implementation you can use this component to update the key mounted component.
 * How? By intercepting the 'general' signal and change key when child components tell us to.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 27 March 2020
 */
export default {
    name: 'ActivityStreamItemResolver',
    components: {},
    props: ['activity'],
    data () {
        return {
            childComponentKey: null,
            childComponent: null
        };
    },
    beforeMount () {
        // Initialize key.
        this.childComponentKey = 1;
    },
    mounted () {
        // Set ASI child component based on Activity type.
        // If activity (or activity type) is invalid, this component will not be displayed.
        // If activity is valid but no ASI components exists, the default component will be displayed (AsiGeneric).
        this.childComponent = ResolverHelper.getComponentBasedOnActivityType(this.activity);
    },
    methods: {
        // This method has nothing to do with parent component!
        // It's a helper to re-render the ASI component but it's called by children components ONLY.
        // Rarely -usually on dev- ASI components update store and it's required to re-mount the ASI.
        onUpdateKey () {
            this.childComponentKey = this.childComponentKey + 1;
        },
        onOrganizingAsiUpdateSuccess ($event) {
            this.$emit('organizing-asi-update-success', $event);
        },
        onOrganizingAsiDeleteSuccess ($event) {
            this.$emit('organizing-asi-delete-success', $event);
        }
    }
};
</script>
