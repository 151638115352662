<template>
    <activity-stream-item-structure :activity="activity" :with-body-below="!!activity.data.sourceDescription">
        <p class="mb-0" slot="description" v-html="$t('sentence', { object: activity.data.personName, actor: activity.data.userLogin, source: $t(activity.data.sourceType) })"></p>
        <div slot="bodyBelow">
            <div class="asi-table-container">
                <table class="table table-sm">
                    <tbody>
                    <tr>
                        <td>{{ $t('Source Type') }}</td>
                        <td>{{ $t(activity.data.sourceType) }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('Source Description') }}</td>
                        <td>{{ activity.data.sourceDescription }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </activity-stream-item-structure>
</template>

<script>
import ActivityStreamItemStructure from './activity-stream-item-structure';

/**
 * Activity Stream Item for type PERSON_CREATE.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 27 March 2020
 */
export default {
    name: 'AsiPersonCreate',
    props: ['activity'],
    components: {
        ActivityStreamItemStructure
    }
};
</script>

<i18n>
{
    "en": {
        "Source Type": "Source Type",
        "Source Description": "Source Description",
        "UNKNOWN": "Unknown",
        "PORTAL": "Portal",
        "FILE_IMPORT": "File Import",
        "ONLINE_SOURCES": "Online Sources",
        "OFFLINE_SOURCES": "Offline Sources",
        "sentence": "Person <b>{object}</b> was created by {actor} from {source}."
    },
    "el": {
        "Source Type": "Τύπος Πηγής",
        "Source Description": "Περιγραφή Πηγής",
        "UNKNOWN": "Άγνωστη Πηγή",
        "PORTAL": "Πλατφόρμα",
        "FILE_IMPORT": "Εισαγωγή Αρχείου",
        "ONLINE_SOURCES": "Online Πηγές",
        "OFFLINE_SOURCES": "Offline Πηγές",
        "sentence": "Ο/Η <b>{object}</b> δημιουργήθηκε από το χρήστη {actor} από {source}."
    }
}
</i18n>
