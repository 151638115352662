<template>
    <div class="activity-stream-item-structure mb-2" v-if="activity">
        <!-- HEADER -->
        <div class="d-flex justify-content-between header px-4 pt-3 pb-2" v-if="displayHeader">
            <div class="header-left">
                <slot name="headerLeft"></slot>
            </div>
            <div class="header-right">
                <slot name="headerRight"></slot>
            </div>
        </div>

        <!-- HEADER DEFAULT -->
        <div class="d-flex justify-content-between header-default px-4 pt-3 pb-3" v-if="displayHeaderDefault">
            <div class="header-left">
                <span><i v-bind:class="iconClass"></i> {{ $t(activity.type) }}</span>
            </div>
            <div class="header-right">
                <span>{{ activity.timestamp | dateLiteral }}</span>
            </div>
        </div>

        <!-- DESCRIPTION -->
        <div class="description px-4 pb-3" v-if="displayDescription">
            <slot name="description"></slot>
        </div>

        <!-- BODY / ABOVE -->
        <div class="body-above px-4 pb-3" v-if="displayBodyAbove">
            <slot name="bodyAbove"></slot>
        </div>

        <!-- BODY / BELOW -->
        <div class="body-below px-4 pb-3" v-if="displayBodyBelow">
            <slot name="bodyBelow"></slot>
        </div>

        <!-- BODY / BELOW TOGGLE TEXT -->
        <div class="body-below-toggle text-center px-4 pb-1" v-if="displayBodyBelowToggle">
            <span class="small text-muted cursor-pointer" @click="bodyBelowOpened = true" v-if="!bodyBelowOpened"><i class="fas fa-ellipsis-h"></i></span>
            <span class="small text-muted cursor-pointer" @click="bodyBelowOpened = false" v-if="bodyBelowOpened"><i class="fas fa-angle-up"></i></span>
        </div>
    </div>
</template>

<script>
import ActivityTypeLogic from '@/common/logic/activity-type.logic';

/**
 * Activity Stream Item Structure : Implements and provides the common structure for all ASI components.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 27 March 2020
 * @lastModifiedAt 24 June 2020
 */
export default {
    name: 'ActivityStreamItemStructure',
    props: {
        activity: {
            type: Object,
            required: false,
            default: null
        },
        withHeader: {
            type: Boolean,
            required: false,
            default: false
        },
        withHeaderDefault: {
            type: Boolean,
            required: false,
            default: true
        },
        withDescription: {
            type: Boolean,
            required: false,
            default: true
        },
        withBodyAbove: {
            type: Boolean,
            required: false,
            default: false
        },
        withBodyBelow: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            bodyBelowOpened: false
        };
    },
    computed: {
        // Props helpers //////////
        displayHeader () {
            return this.withHeader;
        },
        displayHeaderDefault () {
            return this.withHeaderDefault;
        },
        displayDescription () {
            return this.withDescription;
        },
        displayBodyAbove () {
            return this.withBodyAbove;
        },
        displayBodyBelowToggle () {
            return this.withBodyBelow;
        },
        displayBodyBelow () {
            if (!this.withBodyBelow) {
                return false;
            }
            return this.bodyBelowOpened;
        },
        // Styling //////////
        iconClass () {
            return {
                'activity-type-icon': true,
                [ActivityTypeLogic.faIconClass(this.activity)]: true
            };
        }
    }
};
</script>
