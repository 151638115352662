<template>
    <activity-stream-item-structure :activity="activity" :with-body-below="false">
        <div slot="description">
            <p class="mb-0">
                <span class="mr-1" v-html="sentenceValue"></span>
                <component :is="specificComponent"
                           :status="activity.data.target"
                           :hideNoStatus="false"></component>
            </p>
        </div>
    </activity-stream-item-structure>
</template>

<script>
import PersonPoliticsSupportStatusTag from '@/components/Person/PersonPoliticsSupportStatusTag';
import PersonPoliticsVolunteeringStatusTag from '@/components/Person/PersonPoliticsVolunteeringStatusTag';
import ActivityStreamItemStructure from './activity-stream-item-structure';

/**
 * Activity Stream Item for common "politics status property change" Activity Types.
 *
 * Types:
 * - PROPERTY_CHANGE_SUPPORT_STATUS
 * - PROPERTY_CHANGE_VOLUNTEERING_STATUS
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 27 March 2020
 */
export default {
    name: 'AsiPropertyChangePoliticsStatus',
    props: ['activity'],
    components: {
        ActivityStreamItemStructure
    },
    data () {
        return {
            specificComponent: null,
            sentenceValue: null
        };
    },
    mounted () {
        this.buildSentenceValue();
    },
    methods: {
        buildSentenceValue () {
            const actor = this.activity.data.userLogin;
            const target = this.activity.data.personName;

            this.sentenceValue = this.$t('sentence', {
                actor: actor,
                target: target
            });

            switch (this.activity.type) {
            case 'PROPERTY_CHANGE_SUPPORT_STATUS':
                this.specificComponent = PersonPoliticsSupportStatusTag;
                break;
            case 'PROPERTY_CHANGE_VOLUNTEERING_STATUS':
                this.specificComponent = PersonPoliticsVolunteeringStatusTag;
                break;
            default:
                this.specificComponent = null;
                break;
            }
        }
    }
};
</script>

<i18n>
{
    "en": {
        "sentence": "{actor} marked <b>{target}</b> as"
    },
    "el": {
        "sentence": "Ο χρήστης {actor} έθεσε τον/την <b>{target}</b> ως"
    }
}
</i18n>
