<template>
    <div class="ActivityTimelineBox border rounded">
        <!-- BOX WITH OPTIONS -->
        <div class="d-flex align-items-center bg-white">
            <div class="ActivityTimelineBox-Column flex-grow-1"
                 :class="{ 'active': activityType === 'NOTE' }"
                 @click="chooseActivityType('NOTE')">
                <p class="mb-0 weight-5"><i class="far fa-edit"></i><span class="text-microcopy--90 ml-1">{{ $t('New Note') }}</span></p>
            </div>
            <div class="ActivityTimelineBox-Column border-left ml-auto"
                 :class="{ 'active': activityType === 'EMAIL_LOG' }"
                 :title="$t('New Email Log')"
                 @click="chooseActivityType('EMAIL_LOG')">
                <i class="far fa-envelope"></i>
            </div>
            <div class="ActivityTimelineBox-Column border-left"
                 :class="{ 'active': activityType === 'CALL_LOG' }"
                 :title="$t('New Call Log')"
                 @click="chooseActivityType('CALL_LOG')">
                <i class="fas fa-phone"></i>
            </div>
            <div class="ActivityTimelineBox-Column border-left"
                 :class="{ 'active': activityType === 'TASK' }"
                 :title="$t('New Task')"
                 @click="chooseActivityType('TASK')">
                <i class="fas fa-tasks"></i>
            </div>
        </div>

        <!-- EDITOR TITLE AND BODY -->
        <div class="border-top" v-if="active">
            <div class="p-3 bg-white border-bottom" v-if="activityType !== 'NOTE'">
                <p class="mb-0 weight-5">{{ $t(title) }}</p>
            </div>
            <div class="bg-white">
                <organizing-activity-editor :key="componentKey"
                                            :activity-type="activityType"
                                            :for-person-id="personId"
                                            v-on:cancel="produceSignal($event, 'cancel')"
                                            v-on:create-success="produceSignal($event, 'create-success')"
                                            v-on:update-success="produceSignal($event, 'update-success')"
                                            v-on:delete-success="produceSignal($event, 'delete-success')"></organizing-activity-editor>
            </div>
        </div>
    </div>
</template>

<script>
import OrganizingActivityEditor from './organizing-activity-editor';

/**
 * Details for each supported ActivityType.
 *
 * The 'title' attr should be included in i18n translations.
 */
const activityTypeDetails = {
    'NOTE': {
        title: 'New Note'
    },
    'CALL_LOG': {
        title: 'New Call Log'
    },
    'EMAIL_LOG': {
        title: 'New Email Log'
    },
    'TASK': {
        title: 'New Task'
    }
};

/**
 * Box with Organizing Activity creator/editor for Activity Timeline.
 *
 * @author Dimitris Gkoulis
 * @createdAt 19 June 2020
 */
export default {
    name: 'ActivityTimelineBox',
    components: {
        OrganizingActivityEditor
    },
    props: {
        personId: {
            type: String,
            required: false,
            default: null
        }
    },
    data () {
        return {
            active: false,
            activityType: 'NONE',
            componentKey: 'NONE' + new Date().getTime(),
            title: null
        };
    },
    methods: {
        chooseActivityType (activityType) {
            if (!activityTypeDetails.hasOwnProperty(activityType)) return;
            this.activityType = activityType;
            this.componentKey = activityType + new Date().getTime();
            this.title = activityTypeDetails[activityType].title;
            this.active = true;
        },
        resetActivityType () {
            this.active = false;
            this.activityType = 'NONE';
            this.componentKey = 'NONE' + new Date().getTime();
            this.title = null;
        },
        produceSignal (eventData, eventType) {
            if (typeof eventType !== 'string') return;
            this.resetActivityType();
            this.$emit(eventType, eventData);
        }
    }
};
</script>

<style lang="scss">
    .ActivityTimelineBox {
        box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);
        transition: box-shadow 83ms;

        // Add padding inner editor form actions section.
        .OrganizingActivityEditor__FormActions {
            padding: 0.5em 1em 1em 1em;
        }
    }

    .ActivityTimelineBox-Column {
        padding: 0.85em 1.05em;
        cursor: pointer;
        user-select: none;

        background-color: inherit;
        color: #696c6f;

        &.active {
            background-color: #f8f9fa;
        }
        &:hover {
            background-color: #f8f9fa;
            color: rgba(0,0,0,.9);
        }
    }
</style>
