<template>
    <div class="PersonView ViewWrapper LwHaB">
        <clv-head-meta :title="[
            stateIsChanging === true ? $t('common_state_loading') + '...' : null,
            personName,
            $t('Not Found')
        ]"></clv-head-meta>

        <!-- VIEW LOADER -->
        <element-loading :active="stateIsChanging" :is-full-view="true" :lock-parent="true"></element-loading>

        <!-- EMPTY STATE 2020-07-19 -->
        <simple-state-not-found :for-view="true" v-if="displayEmptyState">
            <div class="mt-2" slot="action">
                <router-link tag="a" :to="{ name: 'people' }"><i class="fas fa-chevron-left"></i> {{ $t('Browse People') }}</router-link>
            </div>
        </simple-state-not-found>

        <!-- MAIN -->
        <div class="d-md-flex flex-md-column LwHaB-Main Disable-md" v-if="displayMain">
            <person-headline></person-headline>
            <person-nav></person-nav>
            <component v-bind:is="activeViewComponent" :key="activeViewComponentKey"></component>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash/get';
import RandomUtils from '@/common/utils/random.utils';
import RouterUtils from '@/common/utils/router.utils';
import PersonHeadline from './PersonHeadline';
import PersonNav from './PersonNav';
import PersonTimeline from './PersonTimeline';
import PersonDetails from './PersonDetails';
import PersonComSub from './PersonComSub';
import PersonLifeEvents from './PersonLifeEvents';

/**
 * Main view for Person.
 * Provides 360 view for all Person aspects such as details (properties),
 * activity logs, timeline, communication subscriptions etc.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 June 2020
 * @lastModifiedAt 15 November 2020
 */
export default {
    name: 'PersonView',
    components: {
        PersonHeadline,
        PersonNav
    },
    data () {
        return {
            // View
            activeViewComponent: null,
            activeViewComponentKey: RandomUtils.getUniqueId('None-')
        };
    },
    computed: {
        ...mapGetters('person', {
            // Data
            person: 'person',
            // UI state
            stateIsChanging: 'stateIsChanging',
            displayEmptyState: 'displayEmptyState',
            displayMain: 'displayMain'
        }),
        isCreate () {
            return get(this.person, 'id', null) === null;
        },
        personName () {
            return get(this.person, 'properties.name', null);
        }
    },
    beforeMount () {
        // The listen to changes and use mutations to modify property.
        this.$watch('$route', {
            handler: function (to, from) {
                const result = RouterUtils.resourceIdHelper(to, from);

                // It's important to set 'personId'. It can be null if action is 'create'.
                this.$store.commit('person/setPersonId', result.toId);
                this.$store.commit('personActivityV0/setPersonId', result.toId);

                // On route change, initialize.
                if (result.mustInitialize) {
                    // Initialize "person" store module.
                    this.$store.dispatch('person/initializeModule').then(() => void 0).catch(() => void 0);
                    // Reset "personActivityV0" store and then fetch activities.
                    this.$store.commit('personActivityV0/resetPersonActivityModule', false); // But do not reset filters.
                    this.$store.dispatch('personActivityV0/getActivities').then(() => void 0).catch(() => void 0);
                }

                // View Component.
                const view = to.query.view;
                if (view === 'details') {
                    this.activeViewComponent = PersonDetails;
                    this.activeViewComponentKey = RandomUtils.getUniqueId('PersonDetails-');
                } else if (view === 'communication-subscriptions') {
                    this.activeViewComponent = PersonComSub;
                    this.activeViewComponentKey = RandomUtils.getUniqueId('PersonComSub-');
                } else if (view === 'life-events') {
                    this.activeViewComponent = PersonLifeEvents;
                    this.activeViewComponentKey = RandomUtils.getUniqueId('PersonLifeEvents-');
                } else {
                    this.activeViewComponent = PersonTimeline;
                    this.activeViewComponentKey = RandomUtils.getUniqueId('PersonTimeline-');
                }
            },
            immediate: true
        });
    },
    beforeDestroy () {
        // Reset store.
        this.$store.dispatch('person/resetModule').then(() => void 0).catch(() => void 0);
        // Reset "personActivityV0" store.
        this.$store.commit('personActivityV0/resetPersonActivityModule', false); // But do not reset filters.
    }
};
</script>

<style lang="scss">
    .PersonView {

        .LwHaB-Main__Body__Column {
            // 60px the app nav
            // 70px the inner person headline
            // 31px the inner person nav
            // 30px the desired total margin (top and bottom)
            min-height: calc(100vh - 60px - 70px - 31px - 30px);
            max-height: calc(100vh - 60px - 70px - 31px - 30px);
        }
    }
</style>
