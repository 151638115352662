<template>
    <activity-stream-item-structure :activity="activity" :with-body-below="false">
        <p class="mb-0" slot="description" v-html="sentenceValue"></p>
    </activity-stream-item-structure>
</template>

<script>
import ActivityStreamItemStructure from './activity-stream-item-structure';

/**
 * Activity Stream Item for type 'CAMPAIGN_WEB_LINK_CLICK'.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 27 March 2020
 */
export default {
    name: 'AsiCampaignWebLink',
    props: ['activity'],
    components: {
        ActivityStreamItemStructure
    },
    data () {
        return {
            sentenceValue: null
        };
    },
    mounted () {
        this.buildSentenceValue();
    },
    methods: {
        buildSentenceValue () {
            let sentenceKey = 'sentence_click';
            let identityProfile = this.activity.data.identityProfileName;
            let campaign = this.activity.data.campaignName;
            let broadcaster = this.activity.data.broadcasterName;
            let linkText = this.activity.data.linkText;
            let linkUrl = this.activity.data.linkUrl;

            this.sentenceValue = this.$t(sentenceKey, {
                campaign: campaign,
                broadcaster: broadcaster,
                identityProfile: identityProfile,
                linkText: linkText,
                linkUrl: linkUrl
            });
        }
    }
};
</script>

<i18n>
{
    "en": {
        "sentence_click": "<b>{identityProfile}</b> clicked the link <i>{linkText}</i> (<i>{linkUrl}</i>) inside Campaign <i>{campaign}</i> from Broadcaster <i>{broadcaster}</i>."
    },
    "el": {
        "sentence_click": "<b>{identityProfile}</b> έκανε κλικ στο σύνδεσμο <i>{linkText}</i> (<i>{linkUrl}</i>) στο μήνυμα της Εκστρατείας <i>{campaign}</i> από το Μεταδότη <i>{broadcaster}</i>."
    }
}
</i18n>
