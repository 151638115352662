<template>
    <li class="PersonLifeEventsTimelineItem PersonLifeEventsTimelineItem--Icon--Fa"
        :class="`PersonLifeEventsTimelineItem--Icon--Fa--${personLifeEvent.category}`"
        :id="`PersonLifeEvent-${personLifeEvent.id}`"
        :data-date="personLifeEvent.fullDate"
        v-if="personLifeEvent">
        <p class="mb-1 text-microcopy--75 font-weight-bold">{{ humanReadableDateBasedOnPersonLifeEventDate }}</p>
        <p class="h5 mb-2">{{ personLifeEvent.title }}</p>
        <div class="d-flex justify-content-start align-middle mb-2" v-if="personLifeEvent.specific.personId">
            <small class="text-muted mr-2"><i class="fas fa-user"></i></small>
            <router-link tag="a" class="small" :to="{ name: 'person', params: { id: personLifeEvent.specific.personId } }">
                {{ specificPersonNameOrUnknown }}
            </router-link>
        </div>
        <div class="d-flex justify-content-start align-middle mb-2" v-if="personLifeEvent.specific.workplace">
            <small class="text-muted mr-2"><i class="far fa-building"></i></small>
            <p class="mb-0 small">{{ personLifeEvent.specific.workplace }}</p>
        </div>
        <div class="d-flex justify-content-start align-middle mb-2" v-if="personLifeEvent.specific.educationalOrganization">
            <small class="text-muted mr-2"><i class="fas fa-university"></i></small>
            <p class="mb-0 small">{{ personLifeEvent.specific.educationalOrganization }}</p>
        </div>
        <div class="d-flex justify-content-start align-middle mb-2" v-if="personLifeEvent.specific.location">
            <small class="text-muted mr-2"><i class="fas fa-map-marker-alt"></i></small>
            <p class="mb-0 small">{{ personLifeEvent.specific.location }}</p>
        </div>
        <div class="mb-0">
            <p class="mb-0" v-html="personLifeEvent.description"></p>
        </div>
        <!-- ACTIONS -->
        <b-dropdown variant="link"
                    toggle-class="text-decoration-none"
                    class="PersonLifeEventsTimelineItem__Actions"
                    right
                    no-caret>
            <template v-slot:button-content>
                    <span class="small">
                        <i class="fas fa-chevron-down text-muted"></i>
                    </span>
            </template>
            <b-dropdown-item @click="confirmAndProceedToAction('delete')">{{ $t('common_action_delete') }}</b-dropdown-item>
        </b-dropdown>
    </li>
</template>

<script>
import get from 'lodash/get';
import dateLiteral from '@/common/filters/date-literal.filter';
import { PersonLifeEventService } from '@/common/services/api.service';

/**
 * Person life event timeline item.
 *
 * @see PersonLifeEventsTimeline.vue
 *
 * @author Dimitris Gkoulis
 * @createdAt 15 November 2020
 */
export default {
    name: 'PersonLifeEventsTimelineItem',
    props: {
        personLifeEvent: {
            type: Object,
            default: null,
            required: true
        }
    },
    computed: {
        humanReadableDateBasedOnPersonLifeEventDate () {
            const withOffset = false;
            const fullDate = get(this.personLifeEvent, 'date.fullDate', null);
            // const year = get(this.personLifeEvent, 'date.year', null);
            const month = get(this.personLifeEvent, 'date.month', null);
            const day = get(this.personLifeEvent, 'date.day', null);

            let format = 'YYYY' + (withOffset === true ? ' ZZ' : '');
            if (typeof month === 'number') {
                if (typeof day === 'number') {
                    format = 'DD MMM YYYY' + (withOffset === true ? ' ZZ' : '');
                } else {
                    format = 'MMMM YYYY' + (withOffset === true ? ' ZZ' : '');
                }
            }
            return dateLiteral(fullDate, format, '-');
        },
        specificPersonNameOrUnknown () {
            const specificPersonId = get(this.personLifeEvent, 'specific.personId', null);
            let name = null;
            if (typeof specificPersonId === 'string') {
                name = get(this.personLifeEvent, `extra.personNameMap.${specificPersonId}`, null);
            }
            return typeof name === 'string' ? name : this.$t('Unknown');
        }
    },
    methods: {
        /**
         * Display confirmation message box.
         * If user selects to proceed, it proceeds to the corresponding logic.
         *
         * @param action the action to confirm and proceed to.
         */
        confirmAndProceedToAction (action) {
            if (typeof action !== 'string') return;

            let originalTitle = 'Confirm Action';
            let originalMessage = '';
            if (action === 'delete') {
                originalTitle = 'Delete Life Event?';
                originalMessage = null;
            } else {
                return;
            }

            const messageBoxConfiguration = {
                title: this.$t(originalTitle),
                message: this.$t(originalMessage),
                showCancelButton: true,
                confirmButtonText: this.$t('common_action_okay'),
                cancelButtonText: this.$t('common_action_cancel'),
                closeOnClickModal: false,
                closeOnPressEscape: true,
                roundButton: false,
                showClose: false,
                customClass: 'el-message-box--w400',
                lockScroll: false
            };

            this.$msgbox(messageBoxConfiguration)
                .then((response) => {
                    if (response !== 'confirm') return;

                    if (action === 'delete') {
                        const personLifeEventId = this.personLifeEvent.id;
                        PersonLifeEventService.deletePersonLifeEvent(personLifeEventId)
                            .then(() => {
                                // 15 Nov 2020: Ugly, I know... But what can I do(!)
                                this.$emit('deleted-person-life-event', this.personLifeEvent);
                                this.$store.dispatch('person/getPersonLifeEvents').then(() => void 0).catch(() => void 0);
                            })
                            .catch((reason) => {
                                this.toastErrorFromError(reason);
                            });
                    } else {} // Theoretically unreachable.
                })
                .catch(() => void 0);
        }
    }
};
</script>
