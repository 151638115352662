<template>
    <div class="PersonActivity force-scroll" id="PersonActivity">
        <!-- ACTIVITY BOX (to create organizing activities)-->
        <!--
        it's expected that only create-success affects somehow the activity listing.
        Other events are handle by box or other components.
        -->
        <activity-timeline-box :person-id="personId"
                               v-on:create-success="fetchPersonActivities"></activity-timeline-box>

        <!-- ACTIVITY FILTER -->
        <!--
        <person-activity-filter-popover></person-activity-filter-popover>
        -->
        <person-activity-filter-sidebar></person-activity-filter-sidebar>

        <div class="mt-3"></div>

        <!-- EMPTY STATE 2020-07-19 -->
        <simple-state-empty class="text-center" v-if="personActivityDisplayEmptyState">
            <p class="mb-0 text-muted" slot="title">{{ $t('common_search_no_results') }}</p>
        </simple-state-empty>

        <!-- ERROR STATE 2020-07-19 -->
        <simple-state-error v-if="personActivityDisplayError" :error="personActivityError"></simple-state-error>

        <!-- ACTIVITIES (LISTING) -->
        <activity-stream-item-resolver v-for="(activityItem, index) in personActivityData"
                                       :key="activityItem.vueKey ? activityItem.vueKey : activityItem.id"
                                       :activity="activityItem"
                                       v-on:organizing-asi-update-success="onOrganizingAsiUpdateSuccess($event, index)"
                                       v-on:organizing-asi-delete-success="onOrganizingAsiDeleteSuccess($event, index)"></activity-stream-item-resolver>

        <!-- ACTIVITY PAGINATION (NORMAL not infinite) -->
        <person-activity-pagination></person-activity-pagination>
    </div>
</template>

<script>
import ActivityStreamItemResolver from '@/components/activity-stream-item/activity-stream-item-resolver';
import ActivityTimelineBox from '@/components/activity/activity-timeline-box';
import PersonActivityAccessMixin from '@/modules/PersonActivityV0/mixins/PersonActivityAccessMixin';
import PersonActivityFilterSidebar from '@/modules/PersonActivityV0/views/PersonActivityFilterSidebar';
import PersonActivityPagination from '@/modules/PersonActivityV0/views/PersonActivityPagination';

/**
 * Activity wall for Person view.
 *
 * @author Dimitris Gkoulis
 * @createdAt 26 June 2020
 */
export default {
    name: 'PersonActivity',
    mixins: [
        PersonActivityAccessMixin
    ],
    components: {
        ActivityTimelineBox,
        PersonActivityFilterSidebar,
        ActivityStreamItemResolver,
        PersonActivityPagination
    },
    watch: {
        /**
         * On each loader 'peopleFetching' change, reset the scroll.
         *
         * @see : resetPeopleListingScroll docs.
         */
        'personActivityFetching' () {
            this.resetPersonActivityListingScroll();
        }
    },
    methods: {
        resetPersonActivityListingScroll () {
            try {
                let element = document.getElementById('PersonActivity');
                if (element.classList.contains('force-scroll')) {
                    element.parentElement.scrollTop = 0;
                }
            } catch (ignoredError) {}
        },
        onOrganizingAsiUpdateSuccess ($event, index) {
            this.$store.commit('personActivityV0/replaceActivity', {
                index: index,
                activity: $event
            });
        },
        onOrganizingAsiDeleteSuccess ($event, index) {
            // Check if activities list has only one item and page is greater than 0.
            // In that case, go minus on page and refresh.
            if (this.personActivityData.length === 1 && this.personActivityListingPage > 0) {
                this.personActivityListingPage = this.personActivityListingPage - 1;
                this.fetchPersonActivities();
                return;
            }

            // Otherwise, just remove the activity from the list.
            this.$store.commit('personActivityV0/removeActivity', {
                index: index,
                activity: $event
            });
        }
    }
};
</script>
