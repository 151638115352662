/****************************************************************************************************
 * PersonLifeEvent logic.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 15 November 2020
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';

const PersonLifeEventCategories = [
    {
        name: 'WORK',
        title: 'Work',
        iconFaClass: 'fas fa-briefcase',
        iconFaCode: 'f0b1',
        rtuTitles: [
            'New Job',
            'Promotion',
            'Left Job',
            'Retirement'
        ]
    },
    {
        name: 'EDUCATION',
        title: 'Education',
        iconFaClass: 'fas fa-graduation-cap',
        iconFaCode: 'f19d',
        rtuTitles: [
            'New School',
            'Graduated',
            'Left School'
        ]
    },
    {
        name: 'RELATIONSHIP',
        title: 'Relationship',
        iconFaClass: 'fas fa-heart',
        iconFaCode: 'f004',
        rtuTitles: [
            'New Relationship',
            'Engagement',
            'Marriage',
            'First Met'
        ]
    },
    {
        name: 'HOME_AND_LIVING',
        title: 'Home & Living',
        iconFaClass: 'fas fa-home',
        iconFaCode: 'f015',
        rtuTitles: [
            'Moved',
            'New Vehicle',
            'Cohabitation',
            'New Roommate'
        ]
    },
    {
        name: 'FAMILY',
        title: 'Family',
        iconFaClass: 'fas fa-users',
        iconFaCode: 'f0c0',
        rtuTitles: [
            'New Child',
            'Parenthood',
            'New Pet',
            'Loss of a Loved One'
        ]
    },
    {
        name: 'TRAVEL',
        title: 'Travel',
        iconFaClass: 'fas fa-atlas',
        iconFaCode: 'f558',
        rtuTitles: null
    },
    {
        name: 'INTERESTS_AND_ACTIVITIES',
        title: 'Interests & Activities',
        iconFaClass: 'fas fa-trophy',
        iconFaCode: 'f091',
        rtuTitles: null
    },
    {
        name: 'WELLNESS_AND_HEALTH',
        title: 'Wellness & Health',
        iconFaClass: 'fas fa-spa',
        iconFaCode: 'f5bb',
        rtuTitles: null
    },
    {
        name: 'MILESTONES_AND_ACHIEVEMENTS',
        title: 'Milestones & Achievements',
        iconFaClass: 'fas fa-anchor',
        iconFaCode: 'f13d',
        rtuTitles: null
    },
    {
        name: 'REMEMBRANCE',
        title: 'Remembrance',
        iconFaClass: 'fas fa-birthday-cake',
        iconFaCode: 'f1fd',
        rtuTitles: null
    },
    {
        name: 'CUSTOM',
        title: 'Custom',
        iconFaClass: 'far fa-flag',
        iconFaCode: 'f024',
        rtuTitles: null
    }
];

// eslint-disable-next-line
const PersonLifeEventCategoriesMap = PersonLifeEventCategories.reduce(function (accumulator, current) {
    accumulator[current.name] = current;
    return accumulator;
}, {});

const PersonLifeEventLogic = {
    getPersonLifeEventCategories () {
        return cloneDeep(PersonLifeEventCategories);
    }
};

export default PersonLifeEventLogic;
