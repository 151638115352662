<template>
    <activity-stream-item-structure :activity="activity" :with-body-below="false">
        <div slot="description">
            <p class="mb-0" v-html="sentenceValue"></p>
            <p class="mb-2 small">{{ $t('Associated with') }} <asi-associated-with :person-id-list="associatedWithPersonIdList"></asi-associated-with></p>
            <component :is="barComponent" :activity="activity"></component>
            <div class="asi-organizing-description-wrapper p-3 editable"
                 :title="$t('Double click to edit')"
                 v-on:dblclick="displayEditor = true"
                 v-html="descriptionHtml"
                 v-if="!displayEditor"></div>
            <div v-if="displayEditor">
                <organizing-activity-editor :activity-type="activityType"
                                            :id="activityId"
                                            v-on:cancel="onEditorClose"
                                            v-on:update-success="onOrganizingAsiUpdateSuccess"
                                            v-on:delete-success="onOrganizingAsiDeleteSuccess"></organizing-activity-editor>
            </div>
        </div>
    </activity-stream-item-structure>
</template>

<script>
import OrganizingActivityEditor from '@/components/activity/organizing-activity-editor';
import ActivityStreamItemStructure from './activity-stream-item-structure';
import AsiEditorMixin from './asi-editor-mixin';
import AsiAssociatedWith from './asi-associated-with';
import AsiOrganizingTask from './asi-organizing-task';

/**
 * Activity Stream Item for organizing activities (NOTE, EMAIL_LOG, CALL_LOG, TASK).
 *
 * This component renders organizing-activity-editor.
 * If editor component produce any signal it will be forwarded to resolver
 * and resolver will forward it to its parent component.
 * Only two events must be handled from parent component: "update success" and "delete success".
 * Any other event does not affect the activities listing.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 27 March 2020
 */
export default {
    name: 'AsiOrganizing',
    props: ['activity'],
    mixins: [
        AsiEditorMixin
    ],
    components: {
        OrganizingActivityEditor,
        ActivityStreamItemStructure,
        AsiAssociatedWith
    },
    data () {
        return {
            barComponent: null,
            sentenceValue: null,
            activityId: null,
            activityType: null,
            descriptionHtml: null,
            associatedWithPersonIdList: []
        };
    },
    beforeMount () {
        // Notice: as you can see, 'beforeMount'.
        // This is necessary in order to render properly the activity editor.
        this.prepareData();
    },
    methods: {
        prepareData () {
            this.activityId = this.activity.id;
            this.activityType = this.activity.type;
            if (this.activityType === 'TASK') this.barComponent = AsiOrganizingTask;
            let sentenceKey = 'sentence_' + this.activityType;
            this.descriptionHtml = this.activity.specific.descriptionHtml;
            this.associatedWithPersonIdList = this.activity.specific.personIdList;
            let actor = this.activity.data.userLogin;

            this.sentenceValue = this.$t(sentenceKey, {
                actor: actor
            });
        }
    }
};
</script>

<i18n>
{
    "en": {
        "Associated with": "Associated with",
        "Double click to edit": "Double click to edit",
        "sentence_NOTE": "{actor} left a note.",
        "sentence_EMAIL_LOG": "{actor} logged an email.",
        "sentence_CALL_LOG": "{actor} logged a call.",
        "sentence_TASK": "{actor} added a task."
    },
    "el": {
        "Associated with": "Συσχετίζεται με",
        "Double click to edit": "Διπλό κλικ για επεξεργασία",
        "sentence_NOTE": "Ο χρήστης {actor} δημιούργησε μία σημείωση.",
        "sentence_EMAIL_LOG": "Ο χρήστης {actor} κατέγραψε ένα email.",
        "sentence_CALL_LOG": "Ο χρήστης {actor} κατέγραψε μία κλήση.",
        "sentence_TASK": "Ο χρήστης {actor} πρόσθεσε μία εργασία."
    }
}
</i18n>
