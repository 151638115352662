<template>
    <div class="PersonLifeEventCreatorForm">
        <!-- TITLE -->
        <div class="position-relative text-center mt-3 mb-4">
            <p class="mb-1 text-microcopy--70 cursor-pointer text-primary" @click="close">
                <i class="fas fa-chevron-left"></i> {{ $t('Back to Categories') }}
            </p>
            <p class="mb-0 font-weight-bold text-uppercase">{{ $t('Create Life Event') }}</p>
            <hr/>
            <p class="mb-0 font-weight-bold">{{ $t(lifeEventCategory.title) }}</p>
            <span class="d-inline-flex mt-2 p-2 rounded-circle bg-primary text-white">
                <i :class="lifeEventCategory.iconFaClass"
                   :title="$t(lifeEventCategory.title)"></i>
            </span>
        </div>

        <!-- FORM -->
        <el-form :model="formFields"
                 :rules="formFieldsRules"
                 ref="newInstanceForm"
                 :label-position="'top'"
                 class="cltlr-form-label-style-1"
                 @submit.native.prevent>
            <el-form-item :label="$t('Title')"
                          prop="title"
                          for="title_PersonLifeEventCreator">
                <el-input id="title_PersonLifeEventCreator"
                          v-model="formFields.title"></el-input>
                <!--
                <el-autocomplete v-model="formFields.title"
                                 class="w-100"
                                 :fetch-suggestions="titleQuerySearch"
                                 :trigger-on-focus="true"
                                 @select="onAutocompleteChange"></el-autocomplete>
                <small class="form-text text-muted"
                       v-if="displayTitleAutocompleteHelp">{{ $t('Choose a title or create a new one.') }}</small>
                -->
            </el-form-item>
            <el-form-item :label="$t('Person')"
                          prop="personId"
                          for="personId_PersonLifeEventCreator"
                          v-if="displayPersonId">
                <global-select id="personId_PersonLifeEventCreator"
                               :foreignType="'person'"
                               :foreign-identifier="formFields.personId"
                               v-on:select="handleGlobalSelectSelect"></global-select>
            </el-form-item>
            <el-form-item :label="$t('Workplace')"
                          prop="workplace"
                          for="workplace_PersonLifeEventCreator"
                          v-if="displayWorkspace">
                <el-input id="workplace_PersonLifeEventCreator"
                          v-model="formFields.workplace"></el-input>
                <small class="form-text text-muted">{{ $t('Company, Organization, Institution, etc.') }}</small>
            </el-form-item>
            <el-form-item :label="$t('Educational Organization')"
                          prop="educationalOrganization"
                          for="educationalOrganization_PersonLifeEventCreator"
                          v-if="displayEducationalOrganization">
                <el-input id="educationalOrganization_PersonLifeEventCreator"
                          v-model="formFields.educationalOrganization"></el-input>
                <small class="form-text text-muted">{{ $t('School, University, College, Institution, etc.') }}</small>
            </el-form-item>
            <el-form-item :label="$t('Location')"
                          prop="location"
                          for="location_PersonLifeEventCreator"
                          v-if="displayLocation">
                <el-input id="location_PersonLifeEventCreator"
                          v-model="formFields.location"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Description')"
                          prop="description"
                          for="descriptionPersonLifeEventCreator">
                <el-input id="descriptionPersonLifeEventCreator"
                          type="textarea"
                          v-model="formFields.description"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Date')"
                          prop="date"
                          for="date_PersonLifeEventCreator">
                <div class="input-group">
                    <select class="form-control"
                            v-model="formFields.dateYear">
                        <option v-for="yearOption in selectYearOptions"
                                :value="yearOption"
                                :label="yearOption"
                                :key="yearOption">{{ yearOption }}</option>
                    </select>
                    <select class="form-control"
                            v-model="formFields.dateMonth"
                            v-if="formFields.dateYear">
                        <option :value="null">---</option>
                        <option :value="1">{{ $t('January') }}</option>
                        <option :value="2">{{ $t('February') }}</option>
                        <option :value="3">{{ $t('March') }}</option>
                        <option :value="4">{{ $t('April') }}</option>
                        <option :value="5">{{ $t('May') }}</option>
                        <option :value="6">{{ $t('June') }}</option>
                        <option :value="7">{{ $t('July') }}</option>
                        <option :value="8">{{ $t('August') }}</option>
                        <option :value="9">{{ $t('September') }}</option>
                        <option :value="10">{{ $t('October') }}</option>
                        <option :value="11">{{ $t('November') }}</option>
                        <option :value="12">{{ $t('December') }}</option>
                    </select>
                    <select class="form-control"
                            v-model="formFields.dateDay"
                            v-if="formFields.dateMonth">
                        <option :value="null">---</option>
                        <option v-for="dayOption in selectDayOptions"
                                :value="dayOption"
                                :label="dayOption"
                                :key="dayOption">{{ dayOption }}</option>
                    </select>
                </div>
                <small class="form-text text-muted">{{ $t('date_hierarchical_input_notice_1') }}</small>
            </el-form-item>
            <el-form-item class="mt-3 mb-0">
                <button type="button"
                        class="btn btn-primary btn-block"
                        @click="save">{{ $t('common_action_save') }}</button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import ElValidationUtils from '@/common/utils/el-validation.utils';
import DomainModel from '@/common/logic/domain-models';

/**
 * Person life event creator.
 *
 * IMPORTANT: This components does not listen (or react) to props changes.
 * So, it is required (by parent component) to force re-mount this component
 * when category or title or any other field is changed (outside of this component's scope).
 *
 * Autocomplete is disabled.
 * We use listing to component to display rtu titles.
 * The implementation exists though.
 * If you need this functionality uncomment to enable.
 *
 * NOTICE for @future implementation: If you decide to implement
 * PersonLifeEvent edit, set year, month, day from clientFullDate value.
 *
 * @author Dimitris Gkoulis
 * @createdAt 15 November 2020
 */
export default {
    name: 'PersonLifeEventCreatorForm',
    props: {
        lifeEventCategory: {
            type: Object,
            default: null,
            required: true
        },
        title: {
            type: String,
            default: null,
            required: false
        }
    },
    data () {
        return {
            displayTitleAutocompleteHelp: false,

            formFields: {
                category: 'CUSTOM',
                title: '',
                description: '',
                dateDay: null,
                dateMonth: null,
                dateYear: new Date().getFullYear(),
                fullDate: null,
                clientFullDate: null,
                location: '',
                workplace: '',
                educationalOrganization: '',
                personId: null
            },
            formFieldsRules: {
                category: [],
                title: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(null, 100)
                ],
                description: [
                    ElValidationUtils.lengthValidator(null, 500)
                ],
                location: [
                    ElValidationUtils.lengthValidator(null, 100)
                ],
                workspace: [
                    ElValidationUtils.lengthValidator(null, 100)
                ],
                educationalOrganization: [
                    ElValidationUtils.lengthValidator(null, 100)
                ],
                personId: []
            }
        };
    },
    computed: {
        selectYearOptions () {
            const options = [];
            for (let i = ((new Date().getFullYear()) + 10); i >= 1900; i--) {
                options.push(i);
            }
            return options;
        },
        selectDayOptions () {
            const options = [];
            for (let i = 1; i <= 31; i++) {
                options.push(i);
            }
            return options;
        },
        displayLocation () {
            return ['HOME_AND_LIVING', 'TRAVEL'].includes(get(this.lifeEventCategory, 'name', ''));
        },
        displayWorkspace () {
            return ['WORK'].includes(get(this.lifeEventCategory, 'name', ''));
        },
        displayEducationalOrganization () {
            return ['EDUCATION'].includes(get(this.lifeEventCategory, 'name', ''));
        },
        displayPersonId () {
            return ['RELATIONSHIP', 'FAMILY'].includes(get(this.lifeEventCategory, 'name', ''));
        }
    },
    beforeMount () {
        this.formFields.category = get(this.lifeEventCategory, 'name', 'CUSTOM');
        if (typeof this.title === 'string') {
            this.formFields.title = this.title;
        }
        if (Array.isArray(get(this.lifeEventCategory, 'rtuTitles', null)) &&
            get(this.lifeEventCategory, 'rtuTitles', []).length > 0) {
            this.displayTitleAutocompleteHelp = true;
        }

        const commonDateSyncFunc = function (ignoredValue) {
            let day = typeof this.formFields.dateDay === 'number' ? this.formFields.dateDay : 1;
            const month = typeof this.formFields.dateMonth === 'number' ? (this.formFields.dateMonth - 1) : 0;
            const year = typeof this.formFields.dateYear === 'number' ? this.formFields.dateYear : (new Date().getFullYear());
            // Reset day if month is missing.
            if (typeof this.formFields.dateMonth !== 'number') {
                this.formFields.dateDay = null;
                day = 1;
            }
            this.formFields.fullDate = new Date(year, month, day, 0, 0, 0, 0);
            this.formFields.clientFullDate = this.formFields.fullDate;
        }.bind(this);
        this.$watch('formFields.dateDay', { handler: commonDateSyncFunc });
        this.$watch('formFields.dateMonth', { handler: commonDateSyncFunc });
        this.$watch('formFields.dateYear', { handler: commonDateSyncFunc });
        commonDateSyncFunc(); // Run once...
    },
    methods: {
        /**
         * Close (go back to categories).
         */
        close () {
            this.$emit('close');
        },

        /**
         * On form submit, validate form, transform data and send signal to save Person's life event.
         */
        save () {
            this.$refs['newInstanceForm'].validate((valid) => {
                if (valid === true) {
                    // Get model and transform formFields to PersonLifeEvent.
                    const instance = DomainModel.getPersonLifeEvent();
                    // As this component is unaware of the Person, parent component must set personId.
                    instance.title = this.formFields.title;
                    instance.description = this.formFields.description;
                    instance.category = this.formFields.category;
                    instance.date.fullDate = this.formFields.fullDate; // Back-end will set this field automatically.
                    instance.date.clientFullDate = this.formFields.clientFullDate;
                    instance.date.year = this.formFields.dateYear;
                    instance.date.month = this.formFields.dateMonth;
                    instance.date.day = this.formFields.dateDay;
                    instance.specific.location = this.formFields.location;
                    instance.specific.educationalOrganization = this.formFields.educationalOrganization;
                    instance.specific.workplace = this.formFields.workplace;
                    instance.specific.personId = this.formFields.personId;
                    // Fontawesome icon.
                    instance.extra.iconFaClass = this.lifeEventCategory.iconFaClass;
                    instance.extra.iconFaCode = this.lifeEventCategory.iconFaCode;
                    this.$emit('save', cloneDeep(instance));
                }
            });
        },

        /**
         * Handles GlobalSelect's "select" signal.
         */
        handleGlobalSelectSelect ($event) {
            this.formFields.personId = $event;
        },

        /**
         * Autocomplete results method for el-autocomplete.
         */
        titleQuerySearch (queryString, cb) {
            let cleanedQueryString = '';
            if (typeof queryString === 'string') {
                cleanedQueryString = queryString.toLowerCase().trim();
            }

            let results = [];
            if (!Array.isArray(get(this.lifeEventCategory, 'rtuTitles', null))) {
                results = [];
            } else {
                results = this.lifeEventCategory.rtuTitles
                    // Translate.
                    .map(function (item) {
                        if (this.$te(item)) {
                            return this.$t(item);
                        }
                        return item;
                    })
                    .filter(function (item) {
                        if (typeof item !== 'string') {
                            return false;
                        }
                        const original = item.toLowerCase();
                        return original.startsWith(cleanedQueryString);
                    })
                    .map(function (item) {
                        return {
                            value: item
                        };
                    });
            }

            // call callback function to return suggestions
            cb(results);
        },

        /**
         * On autocomplete change, it's important to trigger form validation
         * in order to remove warning.
         */
        onAutocompleteChange () {
            this.$refs['newInstanceForm'].validate(() => void 0);
        }
    }
};
</script>

<style lang="scss">
    .PersonLifeEventCreatorForm {

        .el-form-item__content {

            .form-text {
                line-height: normal;
            }
        }
    }
</style>
