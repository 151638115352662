<template>
    <activity-stream-item-structure :activity="activity" :with-body-below="false">
        <div slot="description">
            <p class="mb-0">
                <span v-html="sentenceValue"></span>
            </p>
        </div>
    </activity-stream-item-structure>
</template>

<script>
import ActivityStreamItemStructure from './activity-stream-item-structure';

/**
 * Activity Stream Item for type 'PROPERTY_CHANGE_TAXONOMY_LABELS'.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 27 March 2020
 */
export default {
    name: 'AsiPropertyChangeLabels',
    props: ['activity'],
    components: {
        ActivityStreamItemStructure
    },
    data () {
        return {
            sentenceValue: null
        };
    },
    mounted () {
        this.buildSentenceValue();
    },
    methods: {
        buildSentenceValue () {
            const actor = this.activity.data.userLogin;
            const target = this.activity.data.personName;

            const addedList = this.activity.data.added;
            const addedCount = addedList.length;
            const addedLiteral = addedList.map(item => item.name).join(', ');

            const removedList = this.activity.data.removed;
            const removedCount = removedList.length;
            const removedLiteral = removedList.map(item => item.name).join(', ');

            let displayType = 'default';
            if (addedCount > 0 && removedCount > 0) displayType = 'both';
            else if (addedCount > 0) displayType = 'add';
            else if (removedCount > 0) displayType = 'remove';

            const sentenceKey = 'sentence_' + displayType;

            this.sentenceValue = this.$t(sentenceKey, {
                actor: actor,
                target: target,
                addedCount: addedCount,
                addedCountWord: this.$tc('Label', addedCount),
                addedLiteral: addedLiteral,
                removedCount: removedCount,
                removedCountWord: this.$tc('Label', removedCount),
                removedLiteral: removedLiteral
            });
        }
    }
};
</script>

<i18n>
{
    "en": {
        "Label": "Label | Labels",
        "sentence_both": "{actor} marked <b>{target}</b> with {addedCount} {addedCountWord} <i>{addedLiteral}</i> and removed {removedCount} <i>{removedLiteral}</i>.",
        "sentence_add": "{actor} marked <b>{target}</b> with {addedCount} {addedCountWord} <i>{addedLiteral}</i>.",
        "sentence_remove": "{actor} removed removed {removedCount} {removedCountWord} <i>{removedLiteral}</i> from <b>{target}</b>.",
        "sentence_default": "{actor} changed Labels of <b>{target}</b> (No information available)."
    },
    "el": {
        "Label": "Ετικέτα | Ετικέτες",
        "sentence_both": "Ο χρήστης {actor} πρόσθεσε στον/στην <b>{target}</b> {addedCount} {addedCountWord} <i>{addedLiteral}</i> και αφαίρεσε {removedCount} <i>{removedLiteral}</i>.",
        "sentence_add": "Ο χρήστης {actor} πρόσθεσε στον/στην <b>{target}</b> {addedCount} {addedCountWord} <i>{addedLiteral}</i>.",
        "sentence_remove": "Ο χρήστης {actor} αφαίρεσε {removedCount} {removedCountWord} <i>{removedLiteral}</i> από τον/την <b>{target}</b>.",
        "sentence_default": "Ο χρήστης {actor} επεξεργάστηκε τις Ετικέτες του/της <b>{target}</b> (Δεν υπάρχουν διαθέσιμες πληροφορίες)."
    }
}
</i18n>
