<template>
    <div class="PersonComSub flex-grow-1 flex-shrink-1 LwHaB-Main__Body WithMultipleColumns DisableMultipleColumns-sm">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-8">
                    <div class="LwHaB-Main__Body__Column WithMarginTop disable-sm CustomScrollbar--Default bg-white border">
                        <person-com-sub-status-display :communication-subscription-type="'MARKETING_EMAIL'"
                                                       :communication-status="person.metadata.communicationStatuses['MARKETING_EMAIL']"
                                                       :identity-profile-name="person.properties.email"
                                                       :gdpr-add-on-enabled="gdprAddOnEnabled"/>
                        <person-com-sub-status-display :communication-subscription-type="'ONE_TO_ONE_EMAIL'"
                                                       :communication-status="person.metadata.communicationStatuses['ONE_TO_ONE_EMAIL']"
                                                       :identity-profile-name="person.properties.email"
                                                       :gdpr-add-on-enabled="gdprAddOnEnabled"/>
                        <person-com-sub-status-display :communication-subscription-type="'MARKETING_SMS'"
                                                       :communication-status="person.metadata.communicationStatuses['MARKETING_SMS']"
                                                       :identity-profile-name="person.properties.phoneNumber"
                                                       :gdpr-add-on-enabled="gdprAddOnEnabled"/>
                        <person-com-sub-status-display :communication-subscription-type="'ONE_TO_ONE_SMS'"
                                                       :communication-status="person.metadata.communicationStatuses['ONE_TO_ONE_SMS']"
                                                       :identity-profile-name="person.properties.phoneNumber"
                                                       :gdpr-add-on-enabled="gdprAddOnEnabled"/>
                        <person-com-sub-status-display :communication-subscription-type="'MARKETING_CALL'"
                                                       :communication-status="person.metadata.communicationStatuses['MARKETING_CALL']"
                                                       :identity-profile-name="person.properties.phoneNumber"
                                                       :gdpr-add-on-enabled="gdprAddOnEnabled"/>
                        <person-com-sub-status-display :communication-subscription-type="'ONE_TO_ONE_CALL'"
                                                       :communication-status="person.metadata.communicationStatuses['ONE_TO_ONE_CALL']"
                                                       :identity-profile-name="person.properties.phoneNumber"
                                                       :gdpr-add-on-enabled="gdprAddOnEnabled"/>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="LwHaB-Main__Body__Column WithMarginTop disable-sm WithMarginBottom-sm CustomScrollbar--Default">
                        <p class="mb-3 weight-5 small">{{ $t('com_sub_management') }}</p>
                        <person-com-sub-form></person-com-sub-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PersonComSubStatusDisplay from '@/modules/Person/components/PersonComSubStatusDisplay';
import PersonComSubForm from './PersonComSubForm';

/**
 * Displays communication subscriptions statuses and form to create or update communication subscriptions.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 June 2020
 */
export default {
    name: 'PersonComSub',
    components: {
        PersonComSubStatusDisplay,
        PersonComSubForm
    },
    computed: {
        ...mapGetters('person', {
            person: 'person'
        })
    },
    data () {
        return {
            gdprAddOnEnabled: window.cs3w.get('gdprAddOnEnabled', false)
        };
    }
};
</script>

<i18n>
{
    "en": {
        "com_sub_management": "Communication Subscriptions Management"
    },
    "el": {
        "com_sub_management": "Διαχείριση Συνδρομών Επικοινωνίας"
    }
}
</i18n>
