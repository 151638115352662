<template>
    <div class="PersonActivityPagination-Wrapper">
        <!-- min-height: 48px; Uncomment this if you are using "fixed" pagination and is hidden on loading. -->

        <!-- PAGINATION -->
        <div v-show="personActivityDisplayListing">
            <custom-cv-pagination :backward-text="$t('common_pagination_next')"
                                  :forward-text="$t('common_pagination_previous')"
                                  :page-sizes-label="$t('common_pagination_page_size_label', { object: $tc('entity Activity', 2) })"
                                  :number-of-items="personActivityDataCount"
                                  :page="personActivityListingPagePlusOne"
                                  :page-sizes="personActivityListingSizeOptions"
                                  @change="personActivityCarbonPaginationChange"></custom-cv-pagination>
        </div>
    </div>
</template>

<script>
import PersonActivityAccessMixin from '@/modules/PersonActivityV0/mixins/PersonActivityAccessMixin';

/**
 * Person Activity pagination. Wraps IBM Carbon pagination component.
 *
 * @author Dimitris Gkoulis
 * @createdAt 26 June 2020
 */
export default {
    name: 'PersonActivityPagination',
    mixins: [
        PersonActivityAccessMixin
    ]
};
</script>
