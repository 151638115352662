<template>
    <div class="PersonHeadline bg-white border-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="py-custom px-0">
                        <div class="d-flex justify-content-start align-items-center">
                            <avatar :username="person.properties.avatarLetters"
                                    :src="person.properties.avatarUrl"
                                    :size="50"
                                    style="min-width: 50px;"
                                    backgroundColor="#dddddd"
                                    color="#666666"></avatar>
                            <div class="ml-2">
                                <p class="mb-0 weight-7 size-6 line-height-2">
                                    {{ person.properties.name | ellipsis(44) }} <span v-if="person.properties.deceased" :title="$t('Deceased')"> †</span>
                                </p>
                                <p class="mb-0 text-muted small d-none d-sm-block" v-if="fullTitle" :title="fullTitle">{{ fullTitle | ellipsis(60) }}</p>
                            </div>
                            <person-taxonomy-priority-meter-mini :value="person.properties.priority"
                                                                 :extra-class="'ml-auto'"></person-taxonomy-priority-meter-mini>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PersonTaxonomyPriorityMeterMini from '@/components/Person/PersonTaxonomyPriorityMeterMini';

/**
 * PersonHeadline : Person Headline.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 June 2020
 */
export default {
    name: 'PersonHeadline',
    components: {
        PersonTaxonomyPriorityMeterMini
    },
    computed: {
        ...mapGetters('person', {
            person: 'person'
        }),
        fullTitle () {
            return [this.person.properties.title, this.person.properties.department, this.person.properties.organization]
                .filter(function (item) {
                    if (typeof item !== 'string') return false;
                    return item.trim() !== '';
                })
                .join(', ');
        }
    }
};
</script>

<style lang="scss">
    .PersonHeadline {

        .py-custom {
            padding-top: 0.75rem !important;
            padding-bottom: 0.75rem !important;
        }
    }
</style>
