<template>
    <span class="asi-associated-with" v-if="people">
        <span class="associated-with-item" v-for="person in people" :key="person.id">{{ person.foreignName }}</span>
    </span>
</template>

<script>
import { InternalIdentifierService } from '@/common/services/api.service';

/**
 * "Associated With" helper components : given a list of person IDs, fetches and renders their names.
 *
 * @future Implementations:
 * - Click on person? But have in mind tha in most cases we are inside the Person's view. So add the option (as prop) to disable/enable click.
 * - How to order People?
 * - Added prefix (but the parent should provided ready to use (translated))
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 27 March 2020
 */
export default {
    name: 'AsiAssociatedWith',
    props: {
        personIdList: {
            type: Array,
            required: false,
            default: function () {
                return [];
            }
        }
    },
    data () {
        return {
            people: []
        };
    },
    mounted () {
        this.getPersonInternalIdentifiers();
    },
    methods: {
        getPersonInternalIdentifiers () {
            if (!Array.isArray(this.personIdList)) {
                this.people = [];
                return;
            }

            if (this.personIdList.length === 0) {
                this.people = [];
                return;
            }

            // Get all names from internal identifiers source.
            InternalIdentifierService.bulkGetInternalIdentifier(this.personIdList, 'person').then(({ data }) => {
                this.people = data;
            }).catch((reason) => {
                this.people = [];
            });
        }
    }
};
</script>

<style lang="scss" scoped>
    .asi-associated-with {

        .associated-with-item::after {
            content: ", ";
        }
        .associated-with-item:last-child::after,
        .associated-with-item:nth-last-child(2)::after {
            content: "";
        }
        .associated-with-item + .associated-with-item:last-child::before {
            content: " & ";
        }
    }
</style>
