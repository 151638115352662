<template>
    <div class="PersonComSubForm">
        <div class="bg-white border py-2 px-3" v-if="identityProfileId" key="with-identity-profile-id">
            <communication-subscription-creator :identity-profile-id="identityProfileId"
                                                :display-cancel="true"
                                                v-on:cancel="onCancel"
                                                v-on:create-success="onCreateSuccess"></communication-subscription-creator>
        </div>
        <div v-else key="without-identity-profile-id">
            <div v-if="person.identityProfiles.length > 0" key="person-with-identity-profiles">
                <div class="gk-card with-shadow-reactive-1 cursor-pointer"
                     v-for="identityProfile in person.identityProfiles"
                     :key="identityProfile.id"
                     @click="selectIdentityProfile(identityProfile.id)">
                    <div class="d-flex justify-content-start align-items-center">
                        <div>
                            <p class="weight-3 size-6 mb-0" style="word-break: break-all;">{{ identityProfile.name }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else key="person-without-identity-profiles">
                <p class="mb-0">{{ $t('person_com_sub_no_identity_profiles') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CommunicationSubscriptionCreator from '@/modules/Creators/components/CommunicationSubscriptionCreator';

/**
 * A form that creates a Communication Subscription.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 June 2020
 */
export default {
    name: 'PersonComSubForm',
    components: {
        CommunicationSubscriptionCreator
    },
    data () {
        return {
            identityProfileId: null
        };
    },
    computed: {
        ...mapGetters('person', {
            person: 'person'
        })
    },
    methods: {
        selectIdentityProfile (id) {
            this.identityProfileId = id;
        },
        onCancel () {
            this.identityProfileId = null;
        },
        onCreateSuccess () {
            this.identityProfileId = null;
            // Ugly but what can I do?
            // Although it's expected that these actions are totally safe
            // and they do not cause any problems either to the store or to the UX.
            this.$store.dispatch('person/initializeModule').then(() => void 0).catch(() => void 0);
            this.$store.dispatch('personActivityV0/getActivities').then(() => void 0).catch(() => void 0);
        }
    }
};
</script>
