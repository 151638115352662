<template>
    <div class="PersonTimeline flex-grow-1 flex-shrink-1 LwHaB-Main__Body WithMultipleColumns DisableMultipleColumns-sm">
        <div class="container">
            <div class="row">
                <div class="d-none d-md-block col-md-4">
                    <div class="LwHaB-Main__Body__Column WithMarginTop CustomScrollbar--Default">
                        <div class="bg-white border rounded">
                            <person-timeline-about></person-timeline-about>
                            <div class="p-2 text-center">
                                <router-link :to="uiCustomizationRoute"
                                             class="small">
                                    {{ $t('common_action_customize' ) }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-8">
                    <div class="LwHaB-Main__Body__Column WithMarginTop disable-sm WithMarginBottom-sm CustomScrollbar--Default">
                        <person-activity></person-activity>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PersonActivity from '@/modules/PersonActivityV0/views/PersonActivity';
import PersonTimelineAbout from './PersonTimelineAbout';

/**
 * Default sub-view for the Person view.
 * It displays selected information on the left and activity wall on the right.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 June 2020
 * @lastModifiedAt 1 August 2020
 */
export default {
    name: 'PersonTimeline',
    components: {
        PersonTimelineAbout,
        PersonActivity
    },
    computed: {
        uiCustomizationRoute () {
            return {
                name: 'df-flat-ui-customization',
                params: {
                    id: 'person:person_left_sidebar_summary_card'
                },
                query: {
                    backToPerson: this.$store.getters['person/person'].id
                }
            };
        }
    }
};
</script>
