<template>
    <div class="PersonLifeEvents flex-grow-1 flex-shrink-1 LwHaB-Main__Body">
        <div class="container">
            <div class="d-flex justify-content-between align-items-center mt-4">
                <p class="mb-0 size-4 weight-7">{{ $t('Life Events') }}</p>
                <button class="btn btn-secondary-alt"
                        @click="openCreateDialog">{{ $t('Add Life Event') }}</button>
            </div>
            <div class="mt-4 mb-4">
                <!-- EMPTY STATE 2020-07-19 -->
                <simple-state-empty class="text-center" v-if="displayEmptyState">
                    <p class="mb-2" slot="title">{{ $t('common_search_no_results') }}</p>
                    <div slot="action">
                        <button class="btn btn-accent1"
                                @click="openCreateDialog">{{ $t('Add Life Event') }}</button>
                    </div>
                </simple-state-empty>

                <!-- ERROR STATE 2020-07-19 -->
                <simple-state-error v-if="displayErrorState" :error="personLifeEventsError"></simple-state-error>

                <!-- LISTING -->
                <person-life-events-timeline v-if="displayListing">
                    <person-life-events-timeline-item v-for="personLifeEvent in personLifeEvents"
                                                      :person-life-event="personLifeEvent"
                                                      :key="personLifeEvent.id"></person-life-events-timeline-item>
                </person-life-events-timeline>
            </div>
        </div>

        <!-- CREATE DIALOG -->
        <div class="ElementUi-CommonDialog-Wrapper">
            <el-dialog :title="$t('Life Events')"
                       :custom-class="'ElementUi-CommonDialog ElementUi-CommonDialog--Normal'"
                       :top="'20px'"
                       :close-on-click-modal="false"
                       :close-on-press-escape="false"
                       :show-close="true"
                       :lock-scroll="true"
                       :visible.sync="createDialogOpen"
                       v-on:close="closeCreateDialog">
                <person-life-event-creator :key="createDialogKey"
                                           :person-id="personId"
                                           v-on:new-person-life-event="handlePersonLifeEventCreatorNewPersonLifeEvent"></person-life-event-creator>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import get from 'lodash/get';
import { mapGetters } from 'vuex';
import RandomUtils from '@/common/utils/random.utils';
import PersonLifeEventsTimeline from '../components/PersonLifeEventsTimeline';
import PersonLifeEventsTimelineItem from '../components/PersonLifeEventsTimelineItem';
import PersonLifeEventCreator from '../components/PersonLifeEventCreator';

/**
 * Person life events.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 15 November 2020
 */
export default {
    name: 'PersonLifeEvents',
    components: {
        PersonLifeEventsTimeline,
        PersonLifeEventsTimelineItem,
        PersonLifeEventCreator
    },
    data () {
        return {
            createDialogOpen: false,
            createDialogKey: RandomUtils.getUniqueId('PersonLifeEventCreator-')
        };
    },
    computed: {
        ...mapGetters('person', {
            person: 'person',
            personLifeEvents: 'personLifeEvents',
            personLifeEventsLoading: 'personLifeEventsLoading',
            personLifeEventsError: 'personLifeEventsError'
        }),
        personId () {
            return get(this.person, 'id', null);
        },
        displayEmptyState () {
            return this.personLifeEventsLoading === false &&
                this.personLifeEventsError === null &&
                this.personLifeEvents.length === 0;
        },
        displayErrorState () {
            return this.personLifeEventsLoading === false &&
                this.personLifeEventsError !== null;
        },

        /**
         * If personLifeEvents contains items, display them (ignore S.I.)
         */
        displayListing () {
            return this.personLifeEvents.length > 0;
        }
    },
    methods: {
        openCreateDialog () {
            this.createDialogOpen = true;
        },
        closeCreateDialog () {
            this.createDialogOpen = false;
        },

        /**
         * Handles PersonLifeEventCreator's "new-person-life-event" signal.
         *
         * Closes dialog, refreshes person life events and then scrolls to new (persisted) person life event.
         *
         * @param $event the PersonLifeEvent instance.
         */
        handlePersonLifeEventCreatorNewPersonLifeEvent ($event) {
            this.closeCreateDialog();
            this.createDialogKey = RandomUtils.getUniqueId('PersonLifeEventCreator-');
            this.$store.dispatch('person/getPersonLifeEvents')
                .then(() => {
                    const id = $event.id;
                    setTimeout(function () {
                        // 15 Nov 2020 @future Scroll? Yes, but implement after you implement the new UI.
                        // If user wants to add a new life event???
                        // That's why we have to set the header (title - add new btn) visible all the time...
                        const element = document.getElementById(`PersonLifeEvent-${id}`);
                        if (element !== null && element !== undefined) {
                            element.focus();
                        }
                    }, 200);
                })
                .catch(() => void 0);
        }
    }
};
</script>
