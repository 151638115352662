<template>
    <div class="RichEditor" :id="richEditorId" v-if="editor">
        <editor-content class="RichEditor__Content" :editor="editor" :id="richEditorContentId" />

        <div class="RichEditor__MenuBar" :id="richEditorMenuBarId">
            <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
                <div class="RichEditor__MenuBar__Inner">

                    <button class="RichEditor__FormattingButton"
                            :class="{ 'is-active': isActive.bold() }"
                            @click="commands.bold">
                        <i class="fas fa-bold"></i>
                    </button>

                    <button class="RichEditor__FormattingButton"
                            :class="{ 'is-active': isActive.italic() }"
                            @click="commands.italic">
                        <i class="fas fa-italic"></i>
                    </button>

                    <button class="RichEditor__FormattingButton"
                            :class="{ 'is-active': isActive.strike() }"
                            @click="commands.strike">
                        <i class="fas fa-strikethrough"></i>
                    </button>

                    <button class="RichEditor__FormattingButton"
                            :class="{ 'is-active': isActive.underline() }"
                            @click="commands.underline">
                        <i class="fas fa-underline"></i>
                    </button>

                    <button class="RichEditor__FormattingButton"
                            :class="{ 'is-active': isActive.paragraph() }"
                            @click="commands.paragraph">P</button>

                    <button class="RichEditor__FormattingButton"
                            :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                            @click="commands.heading({ level: 1 })">H1</button>

                    <button class="RichEditor__FormattingButton"
                            :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                            @click="commands.heading({ level: 2 })">H2</button>

                    <button class="RichEditor__FormattingButton"
                            :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                            @click="commands.heading({ level: 3 })">H3</button>

                    <button class="RichEditor__FormattingButton"
                            @click="commands.horizontal_rule">HR</button>

                    <button class="RichEditor__FormattingButton"
                            @click="commands.undo">
                        <i class="fas fa-undo"></i>
                    </button>

                    <button class="RichEditor__FormattingButton"
                            @click="commands.redo">
                        <i class="fas fa-redo"></i>
                    </button>

                </div>
            </editor-menu-bar>
        </div>
    </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import {
    HardBreak,
    Heading,
    HorizontalRule,
    Bold,
    Italic,
    Link,
    Strike,
    Underline,
    History
} from 'tiptap-extensions';

/**
 * The Project's RichEditor.
 */
export default {
    name: 'RichEditor',
    components: {
        EditorContent,
        EditorMenuBar
    },
    props: ['content'],
    data () {
        return {
            richEditorId: null,
            richEditorContentId: null,
            richEditorMenuBarId: null,
            editor: null
        };
    },
    beforeMount () {
        let inc = new Date().getTime();
        this.richEditorId = `RichEditor-${++inc}`;
        this.richEditorContentId = `RichEditor-Content-${++inc}`;
        this.richEditorMenuBarId = `RichEditor-MenuBar-${++inc}`;
    },
    mounted () {
        this.editor = new Editor({
            extensions: [
                new HardBreak(),
                new Heading({ levels: [1, 2, 3] }),
                new HorizontalRule(),
                new Link(),
                new Bold(),
                new Italic(),
                new Strike(),
                new Underline(),
                new History()
            ],
            content: this.content,
            onInit: () => {
                // editor is initialized
            },
            onUpdate: ({ getHTML }) => {
                let tmpDoc = document.createElement('DIV');
                tmpDoc.innerHTML = getHTML();
                let text = tmpDoc.textContent || tmpDoc.innerText || '';
                // If text is empty, send an empty text (not empty tags).
                // @future : Send the html but add a flag that tells that the html has no text.
                if (text === '') {
                    this.$emit('change', '');
                } else {
                    this.$emit('change', getHTML());
                }
            }
        });
    },
    beforeDestroy () {
        this.editor.destroy();
    }
};
</script>

<style lang="scss">
    .ProseMirror {
        min-height: 120px;
    }
    .ProseMirror:focus,
    .ProseMirror:active {
        outline: 0 !important;
    }
    .RichEditor__FormattingButton {
        background: transparent;
        font-size: 14px;
        line-height: 14px;
        width: 28px;
        height: 28px;
        border: 0;
        border-radius: 2px;
        cursor: pointer;
        color: #010c2f;

        &.is-active {
            background-color: #f3f3f3;
        }
    }
    .RichEditor__FormattingButton:active,
    .RichEditor__FormattingButton:focus {
        outline: 0;
    }
    .RichEditor__FormattingButton {
        margin-right: 0.3em;
    }
</style>
