<template>
    <div class="FieldValueDisplay" v-if="detail">
        <p class="title">{{ detail.label }}</p>
        <div class="value" v-if="detail.displayAs === 'personTaxonomyLabels'">
            <person-taxonomy-label-tag v-for="(item, index) in detail.value"
                                       :key="index"
                                       :value="{ name: item }"></person-taxonomy-label-tag>
        </div>
        <p v-else-if="detail.displayAs === 'listLiteral'" class="value">{{ detail.value.join(', ') }}</p>
        <p v-else-if="detail.displayAs === 'boolToYesNo'" class="value">{{ detail.value ? $t('Yes') : $t('No') }}</p>
        <p v-else-if="detail.displayAs === 'dateLiteral'" class="value">{{ detail.value | dateLiteral('DD MMM YYYY') }}</p>
        <p v-else class="value">{{ explainValue(detail.value) }}</p>
    </div>
</template>

<script>
/**
 * FieldValueDisplay for Person properties and custom "detail" model.
 * Check store about "detail" model structure.
 *
 * @future implementations:
 * - Add add to clipboard (copy). Either icon or double-click.
 * - Should label be translated? Only for predefined? Hmm, nice idea.
 *
 * @author Dimitris Gkoulis
 * @createdAt 26 June 2020
 */
export default {
    name: 'PersonFieldValueDisplay',
    props: {
        detail: {
            type: Object,
            required: true,
            default: null
        }
    },
    methods: {
        explainValue (value) {
            if (value === null || value === undefined) return '-';
            if (typeof value === 'number' && value < 0) return '-';
            if (typeof value === 'string' && value.trim() === '') return '-';
            return value;
        }
    }
};
</script>
