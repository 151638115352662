<template>
    <div class="PersonLifeEventCreatorListing">
        <!-- TITLE -->
        <div class="position-relative text-center mt-3 mb-4">
            <p class="mb-1 text-microcopy--70 cursor-pointer text-primary"
               @click="setActiveLifeEventCategory(null)"
               v-if="activeLifeEventCategory">
                <i class="fas fa-chevron-left"></i> {{ $t('Back to Categories') }}
            </p>
            <p class="mb-0 font-weight-bold text-uppercase">{{ $t('Select a category') }}</p>
        </div>

        <!-- CATEGORIES RTU TITLES -->
        <div class="" v-if="activeLifeEventCategory" key="rtu-titles-listing">
            <div class="px-2 py-3 border-bottom border-top text-center life-event-category-rtu-title"
                 @click="selectLifeEventCategoryWithTitle(activeLifeEventCategory, null)">
                <p class="mb-0 h6 font-weight-bold">{{ $t('Custom Title') }}</p>
            </div>
            <div class="px-2 py-3 border-bottom text-center life-event-category-rtu-title"
                 v-for="rtuTitle in activeLifeEventCategory.rtuTitles"
                 :key="rtuTitle"
                 @click="selectLifeEventCategoryWithTitle(activeLifeEventCategory, $t(rtuTitle))">
                <p class="mb-0 h6 font-weight-normal">{{ $t(rtuTitle) }}</p>
            </div>
        </div>

        <!-- CATEGORIES LISTING -->
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 align-items-start" v-else key="categories-listing">
            <div class="col text-center mb-1 life-event-category-column"
                 v-for="(lifeEventCategory, index) in lifeEventCategories"
                 :key="index">
                <div class="p-2 pt-4 life-event-category"
                     @click="setActiveLifeEventCategory(lifeEventCategory)">
                    <i class="mb-2" :class="lifeEventCategory.iconFaClass"></i>
                    <p>{{ $t(lifeEventCategory.title) }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PersonLifeEventLogic from '@/common/logic/person-life-event.logic';

/**
 * Person life event creator categories listing.
 *
 * @author Dimitris Gkoulis
 * @createdAt 15 November 2020
 */
export default {
    name: 'PersonLifeEventCreatorListing',
    data () {
        return {
            lifeEventCategories: PersonLifeEventLogic.getPersonLifeEventCategories(),
            activeLifeEventCategory: null
        };
    },
    methods: {
        /**
         * Set active category in order to display rtu titles.
         *
         * If lifeEventCategory has no rtu titles,
         * then it will call 'selectLifeEventCategory' directly.
         */
        setActiveLifeEventCategory (lifeEventCategory) {
            if (lifeEventCategory === null || lifeEventCategory === undefined) {
                this.activeLifeEventCategory = null;
                return;
            }

            if (!Array.isArray(lifeEventCategory.rtuTitles)) {
                this.activeLifeEventCategory = null;
                this.selectLifeEventCategoryWithTitle(lifeEventCategory, null);
                return;
            }
            if (lifeEventCategory.rtuTitles.length === 0) {
                this.activeLifeEventCategory = null;
                this.selectLifeEventCategoryWithTitle(lifeEventCategory, null);
                return;
            }

            this.activeLifeEventCategory = lifeEventCategory;
        },

        /**
         * Sends 'select' signal to parent along with the selected event category.
         */
        selectLifeEventCategoryWithTitle (lifeEventCategory, title) {
            this.activeLifeEventCategory = null;
            this.$emit('select', {
                lifeEventCategory: lifeEventCategory,
                title: typeof title === 'string' ? title : null
            });
        }
    }
};
</script>

<style lang="scss" scoped>
    .life-event-category {
        cursor: pointer;
        transition: background-color 200ms linear;
        min-height: 115px;

        i,
        p {
            color: #050505;
        }
        i {
            font-size: 1.8em;
        }
        p {
            word-break: initial;
        }
    }
    .life-event-category:hover {
        background-color: #f2f2f2;
    }

    .life-event-category-rtu-title {
        cursor: pointer;
        transition: background-color 200ms linear;

        p {
            color: #050505;
            word-break: initial;
        }
    }
    .life-event-category-rtu-title:hover {
        background-color: #f2f2f2;
    }
</style>
