<template>
    <div class="PersonDetails flex-grow-1 flex-shrink-1 LwHaB-Main__Body WithMultipleColumns DisableMultipleColumns-md">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-9">
                    <div id="details-groups-listing"
                         ref="content"
                         class="LwHaB-Main__Body__Column WithMarginTop disable-md WithMarginBottom-md CustomScrollbar--Default bg-white border">
                        <df-property-group v-for="detailGroup in detailsByGroup"
                                           :key="detailGroup.name"
                                           :can-be-closed="true"
                                           :opened="detailsByGroupUiOpened(detailGroup.name)"
                                           :name="detailGroup.name"
                                           :label="detailGroup.label"
                                           :description="detailGroup.description"
                                           v-on:toggle="toggle(detailGroup.name)">
                            <div slot="body" class="py-4 px-4">
                                <person-field-value-display v-for="detail in detailGroup.details"
                                                            :key="detail.name"
                                                            :detail="detail"></person-field-value-display>
                            </div>
                        </df-property-group>
                    </div>
                </div>
                <div class="d-none d-lg-block col-lg-3">
                    <div class="LwHaB-Main__Body__Column WithMarginTop CustomScrollbar--Default">
                        <nav class="nav nav-pills flex-column">
                            <a class="text-left nav-link rounded-0 border-0 weight-5 text-microcopy--90"
                               v-for="detailGroup1 in detailsByGroupBasics"
                               :key="detailGroup1.name"
                               :href="'#' + detailGroup1.name"
                               @click="scrollIntoView($event, detailGroup1.name)">
                                {{ detailGroup1.label }}
                            </a>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DfPropertyGroup from '@/components/df-property-group';
import PersonFieldValueDisplay from '@/modules/Person/components/PersonFieldValueDisplay';

/**
 * Displays all properties grouped by "group".
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 June 2020
 */
export default {
    name: 'PersonDetails',
    components: {
        PersonFieldValueDisplay,
        DfPropertyGroup
    },
    computed: {
        ...mapGetters('person', {
            detailsByGroup: 'detailsByGroup',
            detailsByGroupBasics: 'detailsByGroupBasics',
            detailsByGroupUiOpened: 'detailsByGroupUiOpened'
        })
    },
    methods: {
        // Convenience method to scroll a heading into view.
        scrollIntoView (evt, name) {
            evt.preventDefault();
            // Force open the div.
            this.$store.commit('person/ensureDetailsByGroupUiOpened', name);
            // Then, go to div.
            setTimeout(function () {
                const href = evt.target.getAttribute('href');
                const el = href ? document.querySelector(href) : null;
                if (el) {
                    this.$refs.content.scrollTop = el.offsetTop;
                }
            }.bind(this), 150);
        },
        toggle (name) {
            this.$store.commit('person/toggleDetailsByGroupUiOpened', name);
        }
    }
};
</script>
