<template>
    <div class="PersonTimelineAbout p-3">
        <person-field-value-display v-for="detail in details"
                                    :key="detail.name"
                                    :detail="detail"></person-field-value-display>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PersonFieldValueDisplay from '@/modules/Person/components/PersonFieldValueDisplay';

/**
 * About section in timeline sub-view.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 June 2020
 */
export default {
    name: 'PersonTimelineAbout',
    components: {
        PersonFieldValueDisplay
    },
    computed: {
        ...mapGetters('person', {
            details: 'details'
        })
    }
};
</script>
