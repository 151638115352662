<template>
    <div class="PersonComSubStatusDisplay py-3 px-3 border-bottom">
        <p class="mb-0 weight-5 size-6">
            <i class="fas fa-circle" :class="{ 'text-success' : canBeEstablished, 'text-danger': canBeEstablished === false }"></i>
            {{ $t(communicationSubscriptionType) }} <small v-if="identityProfileName">{{ identityProfileName }}</small>
        </p>
        <p class="mb-0 mt-2" v-if="canBeEstablished === false">
            <span class="badge mr-1" v-for="reason in reasons" :key="reason">{{ $t(reason) }}</span>
        </p>
    </div>
</template>

<script>
function onlyUnique (value, index, self) {
    return self.indexOf(value) === index;
}

/**
 * Displays communication subscription status for a specific communication subscription type.
 *
 * @future implementations:
 * - Show some basic timestamps and who requested the communication subscription
 * - Show history of communication subscriptions
 * - IMPORTANT: Use gdprAddOnEnabled flag.
 *
 * @author Dimitris Gkoulis
 * @createdAt 26 June 2020
 */
export default {
    name: 'PersonComSubStatusDisplay',
    props: {
        // COMMUNICATION_SUBSCRIPTION_TYPE enum value.
        communicationSubscriptionType: {
            type: String,
            required: true,
            default: 'NOT_SPECIFIED'
        },
        // CommunicationStatus Person model
        communicationStatus: {
            type: Object,
            required: true,
            default: null
        },
        // The IdentityProfile e.g. email, phone number, social network, etc.
        identityProfileName: {
            type: String,
            required: true,
            default: ''
        },
        gdprAddOnEnabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            canBeEstablished: false,
            reasons: []
        };
    },
    beforeMount () {
        this.canBeEstablished = this.communicationStatus.eligible === true &&
            this.communicationStatus.valid === true &&
            this.communicationStatus.status === 'OPTED_IN';
        this.reasons = this.communicationStatus.reasons.filter(onlyUnique);
    }
};
</script>
