<template>
    <div class="PersonActivityFilters">
        <div class="wrapper">
            <div class="row actions mb-2">
                <div class="col-12">
                    <span class="text-primary small cursor-pointer" @click="selectAll">{{ $t('Select all') }}</span>
                    <span class="text-primary small cursor-pointer" @click="unselectAll">{{ $t('Unselect all') }}</span>
                    <span class="text-primary small cursor-pointer" @click="selectAllMatched" v-if="displayActionsForMatched">{{ $t('Select matched') }}</span>
                    <span class="text-primary small cursor-pointer" @click="unselectAllMatched" v-if="displayActionsForMatched">{{ $t('Unselect matched') }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <input class="form-control form-control-sm" v-model="query" :placeholder="$t('Type to search')"/>
                </div>
            </div>
            <hr/>
            <div :class="{ 'list-wrapper': withListWrapperClass }">
                <div v-for="activityType in activityListFiltered" :key="activityType.key">
                    <el-checkbox :label="activityType.label"
                                 v-model="activityType.checked"
                                 @change="resetPageAndFetchPersonActivities"></el-checkbox>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PersonActivityAccessMixin from '@/modules/PersonActivityV0/mixins/PersonActivityAccessMixin';

/**
 * A component used by PersonActivityFilter.
 * It renders activity types and checkboxes, and manages actions taken over them.
 *
 * @author Dimitris Gkoulis
 * @createdAt 26 June 2020
 */
export default {
    name: 'PersonActivityFilters',
    mixins: [
        PersonActivityAccessMixin
    ],
    props: {
        // If true, it will append .list-wrapper class to div that holds listing.
        // Required for popover in order to limit its height.
        withListWrapperClass: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data () {
        return {
            query: ''
        };
    },
    computed: {
        activityListFiltered () {
            if (this.query === null) {
                return this.personActivityTypes;
            }
            if (typeof this.query !== 'string') {
                return this.personActivityTypes;
            }
            if (this.query.trim() === '') {
                return this.personActivityTypes;
            }
            return this.personActivityTypes.filter(item => item.label.toLowerCase().includes(this.query.toLowerCase()));
        },
        displayActionsForMatched () {
            if (this.query === null) {
                return false;
            }
            if (typeof this.query !== 'string') {
                return false;
            }
            if (this.query.trim() === '') {
                return false;
            }
            // noinspection RedundantIfStatementJS
            if (this.activityListFiltered.length <= 0) {
                return false;
            }
            return true;
        }
    },
    methods: {
        selectAll () {
            for (let activityType of this.personActivityTypes) {
                // noinspection JSPrimitiveTypeWrapperUsage
                activityType.checked = true;
            }
            // Trigger.
            this.resetPageAndFetchPersonActivities();
        },
        unselectAll () {
            for (let activityType of this.personActivityTypes) {
                // noinspection JSPrimitiveTypeWrapperUsage
                activityType.checked = false;
            }
            // Trigger.
            this.resetPageAndFetchPersonActivities();
        },
        selectAllMatched () {
            for (let activityType of this.activityListFiltered) {
                activityType.checked = true;
            }
            // Trigger.
            this.resetPageAndFetchPersonActivities();
        },
        unselectAllMatched () {
            for (let activityType of this.activityListFiltered) {
                activityType.checked = false;
            }
            // Trigger.
            this.resetPageAndFetchPersonActivities();
        }
    }
};
</script>

<style lang="scss" scoped>
    .PersonActivityFilters {

        .wrapper {
            padding: 1em 0.5em;

            .actions {
                span + span::before {
                    content: " / ";
                }
            }
        }

        // Enable for popover, disable for sidebar.
        .list-wrapper {
            max-height: 35vh;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
</style>
