<template>
    <div class="OrganizingActivityEditor position-relative" v-if="isActivityTypeValid">

        <!-- LOADER -->
        <div class="ElementLoading-Placeholder" v-if="stateIsChanging">
            <element-loading :active="true" background-color="transparent"></element-loading>
        </div>

        <!-- EMPTY STATE 2020-07-19 -->
        <simple-state-not-found class="px-3 py-2" v-if="notFound">
            <p class="mb-0 size-6" slot="title">{{ $t('common_state_not_found') }}</p>
            <div class="mt-1" slot="action">
                <p class="mb-0 small cursor-pointer text-primary" @click="formCancel">
                    <i class="fas fa-chevron-left"></i> {{ $t('common_action_close') }}
                </p>
            </div>
        </simple-state-not-found>

        <!-- FORM ERRORS -->
        <!-- <div></div> -->

        <!-- FORM FIELDS -->
        <div class="OrganizingActivityEditor__FormFields mb-2" v-if="displayForm">
            <!-- EL FORM -->
            <el-form :model="formFields"
                     :rules="formFieldsRules"
                     :ref="'theForm'"
                     size="small"
                     :label-position="'top'" class="cltlr-form-label-style-1" @submit.native.prevent>

                <!-- BASIC COMMON FIELDS FOR ORGANIZING ASI -->
                <div class="d-flex justify-content-between align-items-center OrganizingActivityEditor__CommonFields">

                    <!-- TITLE -->
                    <div v-if="displayFormFieldTitle">
                        <el-input v-model="formFields.title"
                                  :placeholder="$t('Title')"
                                  class="g-width-100"></el-input>
                    </div>

                    <!-- DATE AND TIME (DATETIME PICKER) -->
                    <div v-if="displayFormFieldDateAndTime">
                        <el-date-picker v-model="formFields.dateAndTime"
                                        format="yyyy-MM-dd HH:mm"
                                        type="datetime"
                                        :picker-options="formFieldsOptions.dateTimePickerOptions"
                                        style="width: 100% !important;"></el-date-picker>
                    </div>
                </div>

                <!-- DESCRIPTION (RICH EDITOR) -->
                <div class="mb-2 border-bottom" :class="{ 'border-top': activityType !== 'NOTE' }" v-if="displayFormFieldDescription">
                    <rich-editor :key="idForReactivity"
                                 :content="formFields.description"
                                 v-on:change="onRichEditorChange"></rich-editor>
                </div>

                <!-- TASK TYPE -->
                <!--
                <div class="mb-2" v-if="activityType === 'TASK'">
                    <el-select v-model="formFields.taskType">
                        <el-option :key="'TO DO'" :value="'TO DO'" :label="'To-Do'"></el-option> Xoris to keno to TO DO.
                        <el-option :key="'CALL'" :value="'CALL'" :label="'Call'"></el-option>
                        <el-option :key="'EMAIL'" :value="'EMAIL'" :label="'Email'"></el-option>
                        <el-option :key="'MEETING'" :value="'MEETING'" :label="'Meeting'"></el-option>
                    </el-select>
                </div>
                -->

                <!-- CALL LOG OUTCOME -->
                <!--
                <div class="mb-2" v-if="activityType === 'CALL_LOG'">
                    <el-select v-model="formFields.callLogOutcome">
                        <el-option :key="'UNKNOWN'" :value="'UNKNOWN'" :label="'Unknown'"></el-option>
                        <el-option :key="'NO_ANSWER'" :value="'NO_ANSWER'" :label="'No Answer'"></el-option>
                        <el-option :key="'BUSY'" :value="'BUSY'" :label="'Busy'"></el-option>
                        <el-option :key="'WRONG_NUMBER'" :value="'WRONG_NUMBER'" :label="'Wrong Number'"></el-option>
                        <el-option :key="'LEFT_MESSAGE'" :value="'LEFT_MESSAGE'" :label="'Left Message'"></el-option>
                        <el-option :key="'CONNECTED'" :value="'CONNECTED'" :label="'Connected'"></el-option>
                    </el-select>
                </div>
                -->

                <!-- ASSOCIATED WITH (PEOPLE PICKER) -->
                <!--
                <div>
                    <a href="#">Associated With 2 People</a>
                </div>
                -->

                <!--
                @future: Check these below (and check inside backend domain models)
                - Associated people (like tags)
                - Call Log Outcome
                - Task Type
                - Task Alert
                - Task Assigned to (user)
                -->

            </el-form>
        </div>

        <!-- FORM ACTIONS -->
        <div class="OrganizingActivityEditor__FormActions" v-if="displayForm">
            <div v-if="type === 'CREATOR'">
                <div class="d-flex justify-content-start align-items-center">
                    <button type="button"
                            class="btn btn-primary mr-1"
                            :disabled="disableCreateFormButton"
                            @click="formCreate">{{ $t('common_action_create') }}</button>
                    <button type="button" class="btn btn-secondary" @click="formCancel">{{ $t('common_action_cancel') }}</button>
                </div>
            </div>
            <div v-else-if="type === 'EDITOR'">
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <button type="button"
                                class="btn btn-primary mr-1"
                                :disabled="disableUpdateFormButton"
                                @click="formUpdate">{{ $t('common_action_save') }}</button>
                        <button type="button" class="btn btn-secondary" @click="formCancel">{{ $t('common_action_cancel') }}</button>
                    </div>
                    <button type="button" class="btn btn-link text-danger" @click="formDelete">{{ $t('common_action_delete') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { ActivityService } from '@/common/services/api.service';
import RichEditor from '@/components/patterns/RichEditor';

function createOperationBasedOnType (activityType) {
    if (!activityType) {
        // @help : We expect this error never throw because we hide component if type is not valid.
        throw new Error('activityType does not exist');
    }

    switch (activityType) {
    case 'NOTE':
        return 'createNote';
    case 'CALL_LOG':
        return 'createCallLog';
    case 'EMAIL_LOG':
        return 'createEmailLog';
    case 'TASK':
        return 'createTask';
    default:
        // @help : We expect this error never throw because we hide component if type is not valid.
        throw new Error('activityType is not valid');
    }
}

function updateOperationBasedOnType (activityType) {
    if (!activityType) {
        // @help : We expect this error never throw because we hide component if type is not valid.
        throw new Error('activityType does not exist');
    }

    switch (activityType) {
    case 'NOTE':
        return 'updateNote';
    case 'CALL_LOG':
        return 'updateCallLog';
    case 'EMAIL_LOG':
        return 'updateEmailLog';
    case 'TASK':
        return 'updateTask';
    default:
        // @help : We expect this error never throw because we hide component if type is not valid.
        throw new Error('activityType is not valid');
    }
}

function validateOperationBasedOnType (activityType) {
    if (!activityType) {
        // @help : We expect this error never throw because we hide component if type is not valid.
        throw new Error('activityType does not exist');
    }

    switch (activityType) {
    case 'NOTE':
        return 'validateNote';
    case 'CALL_LOG':
        return 'validateCallLog';
    case 'EMAIL_LOG':
        return 'validateEmailLog';
    case 'TASK':
        return 'validateTask';
    default:
        // @help : We expect this error never throw because we hide component if type is not valid.
        throw new Error('activityType is not valid');
    }
}

const ValidationHelper = {
    validateNote (formFields, instance) {
        let reasons = [];
        // Check if valid.
        if (!formFields.description) {
            reasons.push('Description cannot be empty');
        }
        return reasons;
    },
    validateEmailLog (formFields, instance) {
        let reasons = [];
        // Check if valid.
        if (!formFields.description) {
            reasons.push('Description cannot be empty');
        }
        if (!formFields.dateAndTime) {
            reasons.push('Date and Time field cannot be empty');
        }
        return reasons;
    },
    validateCallLog (formFields, instance) {
        let reasons = [];
        // Check if valid.
        if (!formFields.description) {
            reasons.push('Description cannot be empty');
        }
        if (!formFields.dateAndTime) {
            reasons.push('Date and Time field cannot be empty');
        }
        return reasons;
    },
    validateTask (formFields, instance) {
        let reasons = [];
        // Check if valid.
        if (!formFields.title) {
            reasons.push('Title cannot be empty');
        }
        if (!formFields.dateAndTime) {
            reasons.push('Due Date and Time field cannot be empty');
        }
        return reasons;
    }
};

// @change : Add your default save dto.
const getDefaultSaveDTO = () => {
    return {
        id: null,

        // Common Fields //////////
        title: '',
        descriptionPlain: '',
        descriptionHtml: '',
        fromUser: '',
        dateAndTime: null,
        personIdList: [],

        // Task Fields //////////
        assignedToLogin: null,
        type: 'TODO',
        completed: false,
        completedTimestamp: null,

        // Call Log Fields //////////
        outcome: 'UNKNOWN'
    };
};

/**
 * Editor components support 3 operations: create, update, delete.
 *
 * - Please follow all comments with '@change' keyword to make this work for a specific activity.
 * - Parent component is responsible to close and destroy(!) this component when needed to.
 * - Methods in category operations must always return Promise.
 *
 * This component is display only if activityType is valid and is one of these: 'NOTE|TASK|EMAIL_LOG|CALL_LOG'.
 * When an Activity instance does not exists an error message will be displayed.
 *
 * IMPORTANT: Pros to paron an ena Activity den yparxei emfanizetai minima lathous kai den mporei na epexergastei!
 *
 * @future more fields for specific Activity types. E.g. Task Type, Call Outcome, etc.
 *
 * @author Dimitris Gkoulis
 * @createdAt 19 June 2020
 */
export default {
    name: 'OrganizingActivityEditor',
    components: {
        RichEditor
    },
    props: {
        activityType: {
            type: String,
            required: true,
            default: 'NOTE'
        },
        id: { // The id of instance to edit / delete.
            type: String,
            required: false,
            default: null
        },

        /**
         * This is the first personId to include in 'associatedWith' field.
         * It can be removed.
         * User can add multiple People for one organizing activity.
         *
         * IMPORTANT: This prop is used only for CREATOR. Not EDITOR.
         * In case of editor, the component will load the list from activity (after the fetch inside map method).
         *
         * IMPORTANT: Akoma kai an prokeitai gia EDIT (EDITOR) kalo einai na parexetai ayti i timi o fallback value.
         * Diladi an pame na epexergastoume gia ena Person ena Note pou den yparxei, to component automata tha deixei
         * minima lathous kai tha etoimasei mia CREATE form. Ara kalo einai na yparxei mesa ekei kai to Person (personId)
         * pou brisketai o xristis. VEVAIA den einai aparaitito giati mporei to Activity na mi sxetizetai me ena Person.
         */
        forPersonId: {
            type: String,
            required: false,
            default: null
        }
    },
    data () {
        return {
            idForReactivity: 0,

            type: 'CREATOR', // Accepted values: CREATOR | EDITOR

            // State Indicators (Persistence) //////////
            creating: false,
            updating: false,
            deleting: false,
            fetching: false,

            // Persistence //////////
            instance: null,
            saveDTO: null,

            // Errors (Persistence) //////////
            errorCreate: null,
            errorUpdate: null,
            errorDelete: null,
            errorFetch: null,
            notFound: false,

            // Form Fields and Rules //////////
            // @change : Add your form fields.
            formFields: {
                title: '',
                description: '',
                dateAndTime: new Date(),
                personIdList: [],

                // Task [EDITABLE FIELDS] //////////
                taskAssignedToLogin: null,
                taskType: 'TODO',
                // Task [NOT EDITABLE FIELDS] //////////
                taskCompleted: false,
                taskCompletedTimestamp: null,

                // Call Log [EDITABLE FIELDS] //////////
                callLogOutcome: 'UNKNOWN'
            },
            formFieldsRules: {},
            formFieldsOptions: {
                dateTimePickerOptions: null,
                dateTimePickerOptionsForTask: {
                    shortcuts: [
                        {
                            text: 'Now',
                            onClick (picker) {
                                picker.$emit('pick', new Date());
                            }
                        },
                        {
                            text: 'In 8 hours',
                            onClick (picker) {
                                const date = new Date();
                                date.setTime(date.getTime() + (3600 * 1000 * 8));
                                picker.$emit('pick', date);
                            }
                        },
                        {
                            text: 'In 1 day',
                            onClick (picker) {
                                const date = new Date();
                                date.setTime(date.getTime() + (3600 * 1000 * 24));
                                date.setHours(9, 0, 0, 0);
                                picker.$emit('pick', date);
                            }
                        },
                        {
                            text: 'In 2 days',
                            onClick (picker) {
                                const date = new Date();
                                date.setTime(date.getTime() + (3600 * 1000 * 24 * 2));
                                date.setHours(9, 0, 0, 0);
                                picker.$emit('pick', date);
                            }
                        },
                        {
                            text: 'In 7 days',
                            onClick (picker) {
                                const date = new Date();
                                date.setTime(date.getTime() + (3600 * 1000 * 24 * 7));
                                date.setHours(9, 0, 0, 0);
                                picker.$emit('pick', date);
                            }
                        },
                        {
                            text: 'In 30 days',
                            onClick (picker) {
                                const date = new Date();
                                date.setTime(date.getTime() + (3600 * 1000 * 24 * 30));
                                date.setHours(9, 0, 0, 0);
                                picker.$emit('pick', date);
                            }
                        },
                        {
                            text: 'Next Monday',
                            onClick (picker) {
                                const date = new Date();
                                date.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7);
                                date.setHours(9, 0, 0, 0);
                                picker.$emit('pick', date);
                            }
                        }
                    ]
                }
            }
        };
    },
    computed: {
        isActivityTypeValid () {
            if (!this.activityType) {
                return false;
            }
            return this.activityType === 'NOTE' ||
                this.activityType === 'TASK' ||
                this.activityType === 'EMAIL_LOG' ||
                this.activityType === 'CALL_LOG';
        },
        stateIsChanging () {
            return this.creating || this.updating || this.deleting || this.fetching;
        },
        displayForm () {
            // @help : We 've added the stateIsChanging condition because of the rich editor issue.
            // To rich editor ginetai mount prin ginoun fetch ta dedomena. Auto exei os apotelesma otan ginontai fetch
            // ta dedomena na min allazei to rich editor to opoio exei idi ginei mount kai initialize.
            // return !this.notFound && !this.stateIsChanging;

            // @help : New solution: we ensure reactivity with key change.
            // return !this.notFound;

            // For functional new loader.
            // 2020-02-02.
            return !this.notFound && !this.stateIsChanging;
        },
        disableCreateFormButton () {
            // @future : Check if not changed.
            return ValidationHelper[validateOperationBasedOnType(this.activityType)](this.formFields, this.instance).length > 0;
        },
        disableUpdateFormButton () {
            // @future : Check if not changed.
            return ValidationHelper[validateOperationBasedOnType(this.activityType)](this.formFields, this.instance).length > 0;
        },

        // Flags for fields' visibility //////////
        displayFormFieldTitle () {
            return this.activityType === 'TASK';
        },
        displayFormFieldDescription () {
            return true;
        },
        displayFormFieldDateAndTime () {
            return this.activityType === 'TASK' ||
                this.activityType === 'CALL_LOG' ||
                this.activityType === 'EMAIL_LOG';
        }
    },
    watch: {
        'activityType' () {
            this.reset();
            this.initialize();
        },
        'id' () {
            this.reset();
            this.initialize();
        }
    },
    beforeMount () {},
    mounted () {
        this.initialize();
    },
    methods: {
        changeIdForReactivity () {
            this.idForReactivity = this.idForReactivity + 1;
        },
        onRichEditorChange ($e) {
            this.formFields.description = $e;
        },
        reset () {
            this.type = 'CREATOR';
            this.creating = false;
            this.updating = false;
            this.deleting = false;
            this.fetching = false;
            this.instance = null;
            this.saveDTO = null;
            this.errorCreate = null;
            this.errorUpdate = null;
            this.errorDelete = null;
            this.errorFetch = null;
            this.notFound = false;

            // Reset formFields.
            this.formFields.title = '';
            this.formFields.description = '';
            this.formFields.dateAndTime = new Date();
            this.formFields.personIdList = [];
            this.formFields.taskAssignedToLogin = null;
            this.formFields.taskType = 'TODO';
            this.formFields.taskCompleted = false;
            this.formFields.taskCompletedTimestamp = null;
            this.formFields.callLogOutcome = 'UNKNOWN';
            // @change : Reset your fields.
        },

        // @see : disabled : not working...
        focusFirstElement () {
            // @change : Add your element.
            // This methods is called:
            // - After the mounted.
            // - After every async high level operation.

            // Focus on rich editor.
            try {
                // @see : not working.
                // this.$refs.richEditor.$el.focus();
            } catch (e) {
                console.warn('Cannot focus to element');
            }
        },

        /**
         * The initialization process consists of reset and re-initialization of the whole component.
         */
        initialize () {
            // If id exists, try to fetch instance.
            if (this.id) {
                // Start 'fetching'.
                this.fetching = true;

                // Fetch async operation.
                ActivityService.getOneActivity(this.id).then(({ data }) => {
                    // Get instance and reset error.
                    this.instance = data;
                    this.errorFetch = null;

                    // Change type.
                    this.type = 'EDITOR';

                    // Map instance to formFields so they can be edited by user.
                    this.mapInstanceToFormFields();

                    // Check if provided type is equal to instance [async fetched] activity type.
                    if (this.activityType !== this.instance.type) {
                        console.warn('Prop activityType is not equal to instance.type');
                        this.notFound = true;
                    }
                }).catch((reason) => {
                    // Reset instance and get error.
                    this.instance = null;
                    this.errorFetch = reason;

                    // Change type.
                    this.type = 'CREATOR';

                    // Add 'forPersonId' if exists.
                    // As a fallback value.
                    if (this.forPersonId) {
                        this.formFields.personIdList.push(this.forPersonId);
                    }

                    // Set not found flag to true and warn user inside component.
                    this.notFound = true;
                }).finally(() => {
                    // Stop 'fetching'.
                    this.fetching = false;

                    // Focus element.
                    this.focusFirstElement();

                    // Make some components sensitive to changes.
                    this.changeIdForReactivity();
                });
            } else {
                // The id does not exist, so prepare a create form.

                // Change type.
                this.type = 'CREATOR';

                // Add 'forPersonId' if exists.
                if (this.forPersonId) {
                    this.formFields.personIdList.push(this.forPersonId);
                }

                // If task make some changes on datetime picker.
                if (this.activityType === 'TASK') {
                    // Set the default due-dateTime.
                    const date = new Date();
                    date.setTime(date.getTime() + (3600 * 1000 * 24));
                    date.setHours(9, 0, 0, 0);
                    this.formFields.dateAndTime = date;

                    // Set dateTime picker helpers (ready to use dates).
                    this.formFieldsOptions.dateTimePickerOptions = this.formFieldsOptions.dateTimePickerOptionsForTask;
                } else {
                    this.formFieldsOptions.dateTimePickerOptions = null;
                }

                // Focus element.
                this.focusFirstElement();

                // Make some components sensitive to changes.
                this.changeIdForReactivity();
            }
        },

        // Mappers //////////

        /**
         * Maps the forms fields to DTO based on type.
         * For example: Maps the form fields to Note DTO.
         *
         * Executed after each form submit.
         */
        mapFormFieldsToSaveDTO () {
            // @change : Map your classes.

            // Reset DTO.
            this.saveDTO = null;

            // Get default SaveDTO.
            this.saveDTO = cloneDeep(getDefaultSaveDTO);

            this.saveDTO.id = this.id;
            this.saveDTO.title = this.formFields.title;
            this.saveDTO.descriptionPlain = '';
            this.saveDTO.descriptionHtml = this.formFields.description;
            this.saveDTO.fromUser = '';
            this.saveDTO.dateAndTime = this.formFields.dateAndTime;
            this.saveDTO.personIdList = this.formFields.personIdList;

            // For task.
            if (this.activityType === 'TASK') {
                this.saveDTO.type = this.formFields.taskType;
                this.saveDTO.assignedToLogin = this.formFields.taskAssignedToLogin;
                this.saveDTO.completed = this.formFields.taskCompleted;
                this.saveDTO.completedTimestamp = this.formFields.taskCompletedTimestamp;
            } else if (this.activityType === 'CALL_LOG') {
                this.saveDTO.outcome = this.formFields.callLogOutcome;
            }
        },

        /**
         * Maps DTO (organizing activity or activity) to form fields.
         * Usually, executed after the initial fetch of activity.
         */
        mapInstanceToFormFields () {
            // Get specific from Activity instance.
            const specificObj = get(this.instance, 'specific', null);

            // If tempObj is not null map fields otherwise log warn.
            if (specificObj) {
                this.formFields.title = specificObj.title;
                this.formFields.description = specificObj.descriptionHtml;
                this.formFields.dateAndTime = specificObj.dateAndTime;
                this.formFields.personIdList = specificObj.personIdList;

                // @change : Map your classes.
                if (this.activityType === 'TASK') { // For TASK
                    this.formFields.assignedToLogin = get(specificObj, 'assignedToLogin', null);
                    this.formFields.taskType = get(specificObj, 'type', 'TODO');
                    this.formFields.taskCompleted = get(specificObj, 'completed', false);
                    this.formFields.taskCompletedTimestamp = get(specificObj, 'completedTimestamp', null);
                } else if (this.activityType === 'CALL_LOG') { // For CALL_LOG
                    this.formFields.callLogOutcome = get(specificObj, 'outcome', 'UNKNOWN');
                }
            } else {
                console.warn('OrganizingActivityEditor: mapInstanceToFormFields: specificObj is null');
            }
        },

        // Form Helpers //////////
        validateForm () {
            // @change : Add your logic.
            // This method is responsible for validation. (It must inform user too).
            return true;
            // IMPORTANT: Den to exoume ylopoiisei dioti o basikos elegxos ginetai apo ta koumpia.
            // (computed prop - enable - disable create/update button).
        },

        // Form Controls //////////
        formCancel () {
            // Send signal to parent component.
            // Parent component is responsible to close and destroy(!) this component.
            this.$emit('cancel');
        },
        formCreate () {
            // Check if form is valid.
            let isValid = this.validateForm();
            if (!isValid) {
                return;
            }

            // Start 'creating'
            this.creating = true;

            // Reset errorCreate.
            this.errorCreate = null;

            // Map formFields to saveDTO.
            this.mapFormFieldsToSaveDTO();

            // Create operation.
            let name = createOperationBasedOnType(this.activityType);
            ActivityService[name](this.saveDTO).then(({ data }) => {
                // Set data and reset error.
                this.instance = data;
                this.errorCreate = null;

                // We don't map data because we expect the component to be destroyed.

                // Send signal.
                this.$emit('create-success', this.instance);
            }).catch((reason) => {
                // Set error and reset data.
                this.instance = null;
                this.errorCreate = reason;

                // Send signal.
                this.$emit('create-error', this.errorCreate);
            }).finally(() => {
                // Stop 'creating'
                this.creating = false;

                // Make some components sensitive to changes.
                this.changeIdForReactivity();
            });
        },
        formUpdate () {
            // Check if form is valid.
            let isValid = this.validateForm();
            if (!isValid) {
                return;
            }

            // Start 'updating'
            this.updating = true;

            // Reset errorUpdate.
            this.errorUpdate = null;

            // Map formFields to saveDTO.
            this.mapFormFieldsToSaveDTO();

            // Update operation.
            let name = updateOperationBasedOnType(this.activityType);
            ActivityService[name](this.id, this.saveDTO).then(({ data }) => {
                // Set data and reset error.
                this.instance = data;
                this.errorUpdate = null;

                // We don't map data because we expect the component to be destroyed.

                // Send signal.
                this.$emit('update-success', this.instance);
            }).catch((reason) => {
                // Set error and reset data.
                this.instance = null;
                this.errorUpdate = reason;

                // Send signal.
                this.$emit('update-error', this.errorCreate);
            }).finally(() => {
                // Stop 'updating'
                this.updating = false;

                // Make some components sensitive to changes.
                this.changeIdForReactivity();
            });
        },
        formDelete () {
            // Ask user.
            let resp = confirm(this.$t('Delete Activity?'));
            if (!resp) {
                return;
            }

            // Start 'deleting'
            this.deleting = true;

            // Reset errorDelete.
            this.errorDelete = null;

            // Get safe ID to delete.
            let idToDelete = this.id ? this.id : '';

            // Create operation.
            ActivityService.deleteOneActivity(idToDelete).then(({ data }) => {
                // Reset error and instance.
                this.instance = null;
                this.errorDelete = null;

                // Send signal.
                this.$emit('delete-success', null);
            }).catch((reason) => {
                // Do not reset instance because the operation failed.

                // Set error.
                this.errorDelete = reason;

                // Send signal to parent.
                this.$emit('delete-error', this.errorDelete);
            }).finally(() => {
                // Stop 'deleting'
                this.deleting = false;

                // Make some components sensitive to changes.
                this.changeIdForReactivity();
            });
        }
    }
};
</script>

<style lang="scss">
    .OrganizingActivityEditor {

        .el-input__inner {
            border-radius: 0 !important;;
            border: 0 !important;
            // border: 1px solid red;
        }
        .RichEditor__Content {
            padding: 1em;
        }
        .RichEditor__MenuBar {
            padding: 0 1em;
            background: #fbfbfb;
        }
    }

    .OrganizingActivityEditor__FormFields {}

    .OrganizingActivityEditor__FormActions {}

    .OrganizingActivityEditor__CommonFields > div {
        width: 100%;
    }
    .OrganizingActivityEditor__CommonFields > div + div {
        border-left: 1px solid #dcdcdc;
    }
</style>
