import AsiGeneric from './asi-generic';
import AsiPersonCreate from './asi-person-create';
import AsiPersonUpdate from './asi-person-update';
import AsiPersonDelete from './asi-person-delete';
import AsiPropertyChange from './asi-property-change';
import AsiPropertyChangePoliticsStatus from './asi-property-change-politics-status';
import AsiPropertyChangeMobilizer from './asi-property-change-mobilizer';
import AsiPropertyChangeLabels from './asi-property-change-labels';
import AsiEmailCampaign from './asi-email-campaign';
import AsiCampaignWebLink from './asi-campaign-web-link';
import AsiSmsCampaign from './asi-sms-campaign';
import AsiCommunicationSubscription from './asi-communication-subscription';
import AsiOrganizing from './asi-organizing';

const componentsBasedOnActivityType = {
    'default': {
        component: AsiGeneric
    },

    'PERSON_CREATE': {
        component: AsiPersonCreate
    },
    'PERSON_UPDATE': {
        component: AsiPersonUpdate
    },
    'PERSON_DELETE': {
        component: AsiPersonDelete
    },

    'PROPERTY_CHANGE_EMAIL': {
        component: AsiPropertyChange
    },
    'PROPERTY_CHANGE_PHONE_NUMBER': {
        component: AsiPropertyChange
    },

    'PROPERTY_CHANGE_SUPPORT_STATUS': {
        component: AsiPropertyChangePoliticsStatus
    },

    'PROPERTY_CHANGE_SUPPORT_LEVEL': {
        component: AsiPropertyChange
    },
    'PROPERTY_CHANGE_SUPPORT_INFERRED_LEVEL': {
        component: AsiPropertyChange
    },

    'PROPERTY_CHANGE_VOLUNTEERING_STATUS': {
        component: AsiPropertyChangePoliticsStatus
    },
    'PROPERTY_CHANGE_MOBILIZER': {
        component: AsiPropertyChangeMobilizer
    },

    'PROPERTY_CHANGE_TAXONOMY_PRIORITY': {
        component: AsiPropertyChange
    },
    'PROPERTY_CHANGE_TAXONOMY_LABELS': {
        component: AsiPropertyChangeLabels
    },

    'EMAIL_CAMPAIGN_SEND': {
        component: AsiEmailCampaign
    },
    'EMAIL_CAMPAIGN_REJECT': {
        component: AsiEmailCampaign
    },
    'EMAIL_CAMPAIGN_RENDERING_FAILURE': {
        component: AsiEmailCampaign
    },
    'EMAIL_CAMPAIGN_DELIVERY': {
        component: AsiEmailCampaign
    },
    'EMAIL_CAMPAIGN_OPEN': {
        component: AsiEmailCampaign
    },
    'EMAIL_CAMPAIGN_CLICK': {
        component: AsiEmailCampaign
    },
    'EMAIL_CAMPAIGN_BOUNCE': {
        component: AsiEmailCampaign
    },
    'EMAIL_CAMPAIGN_COMPLAINT': {
        component: AsiEmailCampaign
    },

    'CAMPAIGN_WEB_LINK_CLICK': {
        component: AsiCampaignWebLink
    },

    'SMS_CAMPAIGN_EVENT': {
        component: AsiSmsCampaign
    },

    'COMMUNICATION_SUBSCRIPTION': {
        component: AsiCommunicationSubscription
    },

    'NOTE': {
        component: AsiOrganizing
    },
    'EMAIL_LOG': {
        component: AsiOrganizing
    },
    'CALL_LOG': {
        component: AsiOrganizing
    },
    'TASK': {
        component: AsiOrganizing
    }
};

const ResolverHelper = {
    getComponentBasedOnActivityType (activity) {
        if (activity === null || activity === undefined) return null; // This is an error. Return null (not the default).

        if (typeof activity === 'string') {
            if (componentsBasedOnActivityType.hasOwnProperty(activity)) return componentsBasedOnActivityType[activity].component;
            else return componentsBasedOnActivityType['default'].component;
        }

        if (typeof activity === 'object' && typeof activity['type'] === 'string') {
            if (componentsBasedOnActivityType.hasOwnProperty(activity['type'])) return componentsBasedOnActivityType[activity['type']].component;
            else return componentsBasedOnActivityType['default'].component;
        }

        return null; // This is an error. Return null (not the default).
    }
};

/**
 * Helper for activity-stream-item-resolver.vue component.
 *
 * Based on Activity Type returns the corresponding component.
 */
export default ResolverHelper;
