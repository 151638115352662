<template>
    <activity-stream-item-structure :activity="activity" :with-body-below="false">
        <p class="mb-0" slot="description" v-html="$t('sentence', { object: activity.data.personName, actor: activity.data.userLogin })"></p>
    </activity-stream-item-structure>
</template>

<script>
import ActivityStreamItemStructure from './activity-stream-item-structure';

/**
 * Activity Stream Item for type PERSON_DELETE.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 27 March 2020
 */
export default {
    name: 'AsiPersonDelete',
    props: ['activity'],
    components: {
        ActivityStreamItemStructure
    }
};
</script>

<i18n>
{
    "en": {
        "sentence": "Person <b>{object}</b> was deleted by {actor}."
    },
    "el": {
        "sentence": "Ο/Η <b>{object}</b> διαγράφηκε από το χρήστη {actor}."
    }
}
</i18n>
