<template>
    <div class="PersonActivityFilterSidebar d-flex align-items-center justify-content-end border-bottom mt-3">
        <p class="ml-2 mb-1 text-microcopy--75 font-weight-bold text-right cursor-pointer user-select-none" v-b-toggle.sidebar-backdrop>
            <small><i class="fas fa-filter text-muted"></i></small> ({{activityTypeSelectedNum}}/{{activityTypeSelectedAll}})
        </p>
        <b-sidebar id="sidebar-backdrop"
                   no-header
                   backdrop-variant="dark"
                   backdrop
                   shadow
                   right
                   z-index="10000">
            <div class="px-3 py-2">
                <person-activity-filters></person-activity-filters>
            </div>
        </b-sidebar>
    </div>
</template>

<script>
import PersonActivityAccessMixin from '@/modules/PersonActivityV0/mixins/PersonActivityAccessMixin';
import PersonActivityFilters from '@/modules/PersonActivityV0/views/PersonActivityFilters';

/**
 * Activity Wall filters. Filters are being displayed in (off canvas) sidebar.
 *
 * @see PersonActivityFilters the component that renders activity types.
 * @author Dimitris Gkoulis
 * @createdAt 26 June 2020
 */
export default {
    name: 'PersonActivityFilterSidebar',
    components: {
        PersonActivityFilters
    },
    mixins: [
        PersonActivityAccessMixin
    ],
    computed: {
        // Activity Filter //////////
        activityTypeSelectedAll () {
            return this.personActivityTypes.length;
        },
        activityTypeSelectedNum () {
            return this.personActivityTypes.filter(item => item.checked).length;
        }
    }
};
</script>
