<template>
    <activity-stream-item-structure :activity="activity" :with-body-below="false">
        <p class="mb-0" slot="description" v-html="sentenceValue"></p>
    </activity-stream-item-structure>
</template>

<script>
import ActivityStreamItemStructure from './activity-stream-item-structure';

/**
 * Activity Stream Item for type 'SMS_CAMPAIGN_EVENT'.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 27 March 2020
 */
export default {
    name: 'AsiSmsCampaign',
    props: ['activity'],
    components: {
        ActivityStreamItemStructure
    },
    data () {
        return {
            sentenceValue: null
        };
    },
    mounted () {
        this.buildSentenceValue();
    },
    methods: {
        buildSentenceValue () {
            let broadcaster = this.activity.data.broadcasterName;
            let campaign = this.activity.data.campaignName;
            let identityProfile = this.activity.data.identityProfileName;
            let status = this.activity.data.routeeStatus; // Until u create a general field for all providers.
            let action = this.$t('status_' + status.toUpperCase());
            // toUpperCase is required because Routee sends statuses as common words e.g. Delivered

            this.sentenceValue = this.$t('sentence', {
                campaign: campaign,
                broadcaster: broadcaster,
                identityProfile: identityProfile,
                action: action
            });
        }
    }
};
</script>

<i18n>
{
    "en": {
        "status_UNKNOWN": "probably (failed to process SMS) sent to",
        "status_QUEUED": "queued for send to",
        "status_SENT": "sent to",
        "status_DELIVERED": "delivered to",
        "status_UNDELIVERED": "failed to deliver to",
        "status_FAILED": "failed to send to",
        "status_UNSENT": "failed to send (unsent) to",
        "sentence": "SMS Campaign <i>{campaign}</i> from Broadcaster <i>{broadcaster}</i> {action} <b>{identityProfile}</b>."
    },
    "el": {
        "status_UNKNOWN": "πιθανόν (failed to process SMS) στάλθηκε σε",
        "status_QUEUED": "τοποθετήθηκε σε ουρά για αποστολή σε",
        "status_SENT": "στάλθηκε σε",
        "status_DELIVERED": "παραδόθηκε σε",
        "status_UNDELIVERED": "απέτυχε να παραδοθεί σε",
        "status_FAILED": "απέτυχε να αποσταλεί σε",
        "status_UNSENT": "απέτυχε να αποσταλεί (unsent) σε",
        "sentence": "Η Εκστρατεία SMS <i>{campaign}</i> από το μεταδότη <i>{broadcaster}</i> {action} <b>{identityProfile}</b>."
    }
}
</i18n>
