<template>
    <activity-stream-item-structure :activity="activity" :with-body-below="false">
        <div slot="description">
            <p class="mb-0" v-html="sentenceValue"></p>
            <p class="mt-1 mb-0 small" v-if="isIncident">{{ $t('email_block_notification') }}</p>
        </div>
    </activity-stream-item-structure>
</template>

<script>
import ActivityStreamItemStructure from './activity-stream-item-structure';

/**
 * Activity Stream Item for all types related to Email Campaign.
 *
 * Types:
 * - EMAIL_CAMPAIGN_SEND
 * - EMAIL_CAMPAIGN_REJECT
 * - EMAIL_CAMPAIGN_RENDERING_FAILURE
 * - EMAIL_CAMPAIGN_DELIVERY
 * - EMAIL_CAMPAIGN_OPEN
 * - EMAIL_CAMPAIGN_CLICK
 * - EMAIL_CAMPAIGN_BOUNCE
 * - EMAIL_CAMPAIGN_COMPLAINT
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 27 March 2020
 */
export default {
    name: 'AsiEmailCampaign',
    props: ['activity'],
    components: {
        ActivityStreamItemStructure
    },
    data () {
        return {
            sentenceValue: null,
            isIncident: false
        };
    },
    mounted () {
        this.buildSentenceValue();
    },
    methods: {
        buildSentenceValue () {
            let sentenceKey = 'sentence_' + this.activity.type.replace('EMAIL_CAMPAIGN_', '').toLowerCase();
            let recipientEmail = this.activity.data.identityProfileName; // or .data.email
            let emailCampaign = this.activity.data.emailCampaignName;
            let broadcaster = this.activity.data.broadcasterName;
            let linkText = this.activity.data.linkText;
            let linkUrl = this.activity.data.linkUrl;

            switch (this.activity.type) {
            case 'EMAIL_CAMPAIGN_BOUNCE':
            case 'EMAIL_CAMPAIGN_COMPLAINT':
                this.isIncident = true;
                break;
            default:
                this.isIncident = false;
                break;
            }

            this.sentenceValue = this.$t(sentenceKey, {
                emailCampaign: emailCampaign,
                broadcaster: broadcaster,
                recipientEmail: recipientEmail,
                linkText: linkText,
                linkUrl: linkUrl
            });
        }
    }
};
</script>

<i18n>
{
    "en": {
        "sentence_send": "Email Campaign <i>{emailCampaign}</i> from Broadcaster <i>{broadcaster}</i> sent to <b>{recipientEmail}</b>.",
        "sentence_reject": "Email Campaign <i>{emailCampaign}</i> from Broadcaster <i>{broadcaster}</i> <span class='text-danger'>failed</span> to send to <b>{recipientEmail}</b>.",
        "sentence_rendering_failure": "Email Campaign <i>{emailCampaign}</i> from Broadcaster <i>{broadcaster}</i> <span class='text-danger'>failed</span> to send to <b>{recipientEmail}</b>.",
        "sentence_delivery": "Email Campaign <i>{emailCampaign}</i> from Broadcaster <i>{broadcaster}</i> delivered to <b>{recipientEmail}</b>.",
        "sentence_open": "<b>{recipientEmail}</b> opened Email Campaign <i>{emailCampaign}</i> from Broadcaster <i>{broadcaster}</i>.",
        "sentence_click": "<b>{recipientEmail}</b> clicked the link <i>{linkText}</i> (<i>{linkUrl}</i>) inside Email Campaign <i>{emailCampaign}</i> from Broadcaster <i>{broadcaster}</i>.",
        "sentence_bounce": "Email Campaign <i>{emailCampaign}</i> from Broadcaster <i>{broadcaster}</i> <span class='text-danger'>failed</span> to send to <b>{recipientEmail}</b>. The email has bounced.",
        "sentence_complaint": "<b>{recipientEmail}</b> marked Email Campaign <i>{emailCampaign}</i> from Broadcaster <i>{broadcaster}</i> as SPAM.",
        "email_block_notification": "The system may block this email address from further sending."
    },
    "el": {
        "sentence_send": "Η Εκστρατεία Email <i>{emailCampaign}</i> από το Μεταδότη <i>{broadcaster}</i> στάλθηκε σε <b>{recipientEmail}</b>.",
        "sentence_reject": "Η Εκστρατεία Email <i>{emailCampaign}</i> από το Μεταδότη <i>{broadcaster}</i> <span class='text-danger'>απέτυχε</span> να αποσταλεί σε <b>{recipientEmail}</b>.",
        "sentence_rendering_failure": "Η Εκστρατεία Email <i>{emailCampaign}</i> από το Μεταδότη <i>{broadcaster}</i> <span class='text-danger'>απέτυχε</span> να αποσταλεί σε <b>{recipientEmail}</b>.",
        "sentence_delivery": "Η Εκστρατεία Email <i>{emailCampaign}</i> από το Μεταδότη <i>{broadcaster}</i> παραδόθηκε σε <b>{recipientEmail}</b>.",
        "sentence_open": "<b>{recipientEmail}</b> άνοιξε το μήνυμα της Εκστρατείας Email <i>{emailCampaign}</i> από το Μεταδότη <i>{broadcaster}</i>.",
        "sentence_click": "<b>{recipientEmail}</b> έκανε κλικ στο σύνδεσμο <i>{linkText}</i> (<i>{linkUrl}</i>) στο μήνυμα της Εκστρατείας Email <i>{emailCampaign}</i> από το Μεταδότη <i>{broadcaster}</i>.",
        "sentence_bounce": "Η Εκστρατεία Email <i>{emailCampaign}</i> από το Μεταδότη <i>{broadcaster}</i> <span class='text-danger'>απέτυχε</span> να αποσταλεί σε <b>{recipientEmail}</b>. Το μήνυμα χαρακτηρίστηκε ως bounce.",
        "sentence_complaint": "<b>{recipientEmail}</b> χαρακτήρισε το μήνυμα της Εκστρατείας <i>{emailCampaign}</i> από το Μεταδότη <i>{broadcaster}</i> ως Ανεπιθύμητη Αλληλογραφία (SPAM).",
        "email_block_notification": "Το σύστημα ενδέχεται να αποκλείσει οριστικά τη διεύθυνση ηλεκτρονικού ταχυδρομείου από μελλοντικές αποστολές."
    }
}
</i18n>
