<template>
    <div class="d-flex justify-content-start align-items-center mt-4 mb-4">
        <input class="mr-2" type="checkbox" v-model="completed" :disabled="loading"/>
        <p class="mb-0 weight-4 size-5 mr-1 task-title" v-bind:class="taskTitleClass">{{ activity.specific.title }}</p>
        <i v-if="completed" :title="$t('Completed')" class="far fa-check-circle text-success"></i>
        <span class="gk-tag gk-tag-danger" v-if="isOverdue">{{ $t('Overdue') }}</span>
    </div>
</template>

<script>
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { ActivityService } from '@/common/services/api.service';

/**
 * Activity Stream Item helper component for type 'TASK'.
 *
 * @future Implementations:
 * - Fix styles (especially tag).
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 27 March 2020
 */
export default {
    name: 'AsiOrganizingTask',
    props: ['activity'],
    data () {
        return {
            loading: false,
            completed: false
        };
    },
    computed: {
        taskTitleClass () {
            return this.completed ? 'strikethrough' : '';
        },
        isOverdue () {
            // If task is completed, IS NOT overdue.
            if (this.completed) {
                return false;
            }

            let dueDateTime = get(this.activity, 'specific.dateAndTime', null);
            let dueDateTimeJsDate = null;

            try {
                dueDateTimeJsDate = new Date(dueDateTime);
            } catch (e) {
                console.warn('Could not get date and time from activity. Cannot check if overdue. Returning false.');
                dueDateTimeJsDate = null;
            }

            // Check dateAndTime (due datetime).
            if (!dueDateTimeJsDate) {
                return false;
            }

            // Get now.
            let now = new Date();

            // Compare and return.
            return dueDateTimeJsDate < now;
        }
    },
    beforeMount () {
        // Get field 'completed' from activity.
        this.completed = get(this.activity, 'specific.completed', false);

        // Set watcher.
        this.$watch('completed', {
            handler: debounce(function (value) {
                const activityId = get(this.activity, 'id', '');
                ActivityService.changeTaskCompletion(activityId, value).then(() => {}).catch((reason) => {}); // @future Handle.
            }, 400)
        });
    }
};
</script>

<style lang="scss" scoped>
    .task-title {
        line-height: 1.2;
    }
</style>

<i18n>
{
    "en": {
        "Completed": "Completed",
        "Overdue": "Overdue"
    },
    "el": {
        "Completed": "Ολοκληρωμένη",
        "Overdue": "Εκπρόθεσμη"
    }
}
</i18n>
