<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex';
import {
    mapFields,
    mapMultiRowFields
} from 'vuex-map-fields';

const STORE_MODULE = 'personActivityV0';

/**
 * A mixin that provides quick access to "personActivity" store module.
 *
 * @author Dimitris Gkoulis
 * @createdAt 26 June 2020
 */
export default {
    name: 'PersonActivityAccessMixin',
    data () {
        return {
            // Listing Options for Person Activity List.
            personActivityListingSizeAvailableOptions: [
                {
                    value: 10,
                    selected: false
                },
                {
                    value: 20,
                    selected: false
                },
                {
                    value: 30,
                    selected: true
                },
                {
                    value: 40,
                    selected: false
                },
                {
                    value: 50,
                    selected: false
                }
            ]
        };
    },
    computed: {
        ...mapGetters(STORE_MODULE, {
            personId: 'personId',
            personActivityFetching: 'fetching',
            personActivityData: 'data',
            personActivityDataCount: 'dataCount',
            personActivityError: 'error',
            personActivityDisplayListing: 'displayListing',
            personActivityDisplayEmptyState: 'displayEmptyState',
            personActivityDisplayError: 'displayError'
        }),
        // ...mapFields(STORE_MODULE, []),
        ...mapFields(STORE_MODULE, {
            personActivityListingPage: 'listingPage',
            personActivityListingSize: 'listingSize'
        }),
        // ...mapMultiRowFields(STORE_MODULE, []),
        ...mapMultiRowFields(STORE_MODULE, {
            personActivityTypes: 'activityTypes'
        }),
        // Pagination Helpers //////////
        personActivityListingSizeOptions () {
            let options = cloneDeep(this.personActivityListingSizeAvailableOptions);
            for (let item of options) {
                if (item.value === this.personActivityListingSize) {
                    item.selected = true;
                } else {
                    item.selected = false;
                }
            }
            // @future : Check if one option is selected. Else, set the default.
            return options;
        },
        personActivityListingPagePlusOne () {
            return this.personActivityListingPage + 1;
        },

        /**
         * This is very useful so the activity wall can be fetched without hiding the activities.
         * Very useful when the user deletes or updates a specific activity.
         *
         * IMPORTANT: This is currently not used. WE DISPLAY ACTIVITIES REGARDLESS STATE AND OTHER DATA (ERROR, COUNT, etc).
         */
        personActivityDisplayListingRegardlessOfState () {
            return true;
        }
    },
    methods: {
        resetPageAndFetchPersonActivities () {
            this.personActivityListingPage = 0;
            this.$store.dispatch(STORE_MODULE + '/getActivities').then(() => void 0).catch(() => void 0);
        },
        fetchPersonActivities () {
            this.$store.dispatch(STORE_MODULE + '/getActivities').then(() => void 0).catch(() => void 0);
        },
        personActivityCarbonPaginationChange (e) {
            let start = e.start;
            let size = e.length;
            let page = (start - 1) / size;

            // If pagination not changed, do nothing.
            if (this.personActivityListingPage === page && this.personActivityListingSize === size) return;

            this.personActivityListingPage = page;
            this.personActivityListingSize = size;

            this.$store.dispatch(STORE_MODULE + '/getActivities').then(() => void 0).catch(() => void 0);
        }
    }
};
</script>
