<template>
    <div class="PropertyGroup" :id="name">
        <div class="PropertyGroup__Header border-bottom">
            <div class="py-3 px-4">
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <p class="mb-0 weight-5 size-6">{{ label }}</p>
                        <p class="mb-0 text-microcopy--75 text-muted" v-if="description">{{ description }}</p>
                    </div>
                    <div v-if="canBeClosed">
                        <p class="mb-0 cursor-pointer user-select-none" @click="toggle">
                            <i class="far fa-window-minimize" v-if="opened" key="icon-for-opened"></i>
                            <i class="fas fa-plus" v-else key="icon-for-closed"></i>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="PropertyGroup__Body border-bottom" v-show="opened">
            <slot name="body"></slot>
        </div>
    </div>
</template>

<script>
/**
 * A structural component for PersonView and PersonEditView views.
 *
 * @author Dimitris Gkoulis
 * @createdAt 20 June 2020
 */
export default {
    name: 'DfPropertyGroup',
    props: {
        canBeClosed: {
            type: Boolean,
            required: false,
            default: false
        },
        opened: {
            type: Boolean,
            required: false,
            default: false
        },
        name: {
            type: String,
            required: true,
            default: 'propertyGroup' + new Date().getTime()
        },
        label: {
            type: String,
            required: true,
            default: 'Property Group'
        },
        description: {
            type: String,
            required: false,
            default: null
        }
    },
    methods: {
        toggle () {
            this.$emit('toggle', {
                name: this.name,
                currentState: this.opened,
                newState: !this.opened
            });
        }
    }
};
</script>
