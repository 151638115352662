<template>
    <div class="PersonLifeEventCreator position-relative">
        <element-loading :active="personLifeEventLoading" :lock-parent="true"></element-loading>

        <person-life-event-creator-form v-if="selectedLifeEventCategory"
                                        :life-event-category="selectedLifeEventCategory"
                                        :title="selectedTitle"
                                        key="PersonLifeEventCreatorForm"
                                        v-on:close="handlePersonLifeEventCreatorFormClose"
                                        v-on:save="handlePersonLifeEventCreatorFormSave"></person-life-event-creator-form>
        <person-life-event-creator-listing v-else
                                           key="PersonLifeEventCreatorListing"
                                           v-on:select="handlePersonLifeEventCreatorListingSelect"></person-life-event-creator-listing>
    </div>
</template>

<script>
import { PersonLifeEventService } from '@/common/services/api.service';
import PersonLifeEventCreatorListing from './PersonLifeEventCreatorListing';
import PersonLifeEventCreatorForm from './PersonLifeEventCreatorForm';

/**
 * Person life event creator.
 *
 * @author Dimitris Gkoulis
 * @createdAt 15 November 2020
 */
export default {
    name: 'PersonLifeEventCreator',
    props: {
        personId: {
            type: String,
            required: true,
            default: null
        }
    },
    components: {
        PersonLifeEventCreatorForm,
        PersonLifeEventCreatorListing
    },
    data () {
        return {
            selectedLifeEventCategory: null,
            selectedTitle: null,
            personLifeEventLoading: false
        };
    },
    methods: {
        /**
         * Handles PersonLifeEventCreatorForm's signal for close.
         */
        handlePersonLifeEventCreatorFormClose () {
            this.selectedLifeEventCategory = null;
        },

        /**
         * Handles PersonLifeEventCreatorForm's signal for save.
         *
         * This handler assumes that data from child component is valid!
         */
        handlePersonLifeEventCreatorFormSave ($event) {
            this.personLifeEventLoading = true;

            // $event is a PersonLifeEvent instance.
            // noinspection UnnecessaryLocalVariableJS
            const instance = $event;
            // Set current personId. Child form component is unaware of current person.
            instance.personId = typeof this.personId === 'string' ? this.personId : null;

            PersonLifeEventService.createPersonLifeEvent(instance)
                .then(({ data }) => {
                    this.$emit('new-person-life-event', data);
                })
                .catch((reason) => {
                    this.toastErrorFromError(reason);
                })
                .finally(() => {
                    this.personLifeEventLoading = false;
                });
        },

        /**
         * Handles PersonLifeEventCreatorListing's signal for select.
         */
        handlePersonLifeEventCreatorListingSelect ($event) {
            if ($event === null || $event === undefined) {
                return;
            }
            this.selectedLifeEventCategory = $event.lifeEventCategory;
            this.selectedTitle = $event.title;
        }
    }
};
</script>

<style lang="scss">
    .PersonLifeEventCreator {

        .life-event-category {
            cursor: pointer;
            transition: background-color 200ms linear;
            min-height: 115px;

            i,
            p {
                color: #050505;
            }
            i {
                font-size: 1.8em;
            }
            p {
                word-break: initial;
            }
        }
        .life-event-category:hover {
            background-color: #f2f2f2;
        }
    }
</style>
