<template>
    <activity-stream-item-structure :activity="activity" :with-body-below="true">
        <div slot="bodyBelow">
            <div class="asi-table-container">
                <table class="table table-sm">
                    <tbody>
                    <tr>
                        <td>Type</td>
                        <td>{{ activity.type }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </activity-stream-item-structure>
</template>

<script>
import ActivityStreamItemStructure from './activity-stream-item-structure';

/**
 * Activity Stream Item Generic component : Displays common (unprocessed) information about an Activity instance.
 *
 * Avoid using this in production. Acceptable uses:
 * - development
 * - debugging
 * - fallback if Activity component is not registered
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 27 March 2020
 */
export default {
    name: 'AsiGeneric',
    props: ['activity'],
    components: {
        ActivityStreamItemStructure
    }
};
</script>
