<template>
    <activity-stream-item-structure :activity="activity" :with-body-below="false">
        <div slot="description">
            <p class="mb-0">
                <span v-html="sentenceValue"></span>
                <span class="text-success ml-2" v-if="characterization === 'POSITIVE'"><i class="fas fa-long-arrow-alt-up"></i></span>
                <span class="text-danger ml-2" v-if="characterization === 'NEGATIVE'"><i class="fas fa-long-arrow-alt-down"></i></span>
            </p>
        </div>
    </activity-stream-item-structure>
</template>

<script>
import ActivityTypeLogic from '@/common/logic/activity-type.logic';
import ActivityStreamItemStructure from './activity-stream-item-structure';

/**
 * Activity Stream Item for common "property change" Activity Types.
 *
 * Types:
 * - PROPERTY_CHANGE_EMAIL
 * - PROPERTY_CHANGE_PHONE_NUMBER
 * - PROPERTY_CHANGE_SUPPORT_LEVEL
 * - PROPERTY_CHANGE_SUPPORT_INFERRED_LEVEL
 * - PROPERTY_CHANGE_TAXONOMY_PRIORITY
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 27 March 2020
 */
export default {
    name: 'AsiPropertyChange',
    props: ['activity'],
    components: {
        ActivityStreamItemStructure
    },
    data () {
        return {
            sentenceValue: null,
            characterization: null
        };
    },
    mounted () {
        this.buildSentenceValue();
        this.buildCharacterization();
    },
    methods: {
        buildSentenceValue () {
            const actor = this.activity.data.userLogin;
            const target = this.activity.data.personName;
            const propertyChangeType = this.activity.data.type.toLowerCase();
            const sentenceKey = 'sentence_' + propertyChangeType;
            const propertyOldValue = this.activity.data.source;
            const propertyNewValue = this.activity.data.target;
            const property = ActivityTypeLogic.propertyName(this.activity, 'field');

            this.sentenceValue = this.$t(sentenceKey, {
                actor: actor,
                target: target,
                propertyOldValue: propertyOldValue,
                propertyNewValue: propertyNewValue,
                property: property
            });
        },
        buildCharacterization () {
            const propertyChangeCharacterization = this.activity.data.characterization;

            switch (this.activity.type) {
            case 'PROPERTY_CHANGE_TAXONOMY_PRIORITY':
            case 'PROPERTY_CHANGE_SUPPORT_LEVEL':
            case 'PROPERTY_CHANGE_SUPPORT_INFERRED_LEVEL':
                this.characterization = propertyChangeCharacterization;
                break;
            default:
                this.characterization = null;
                break;
            }
        }
    }
};
</script>

<i18n>
{
    "en": {
        "sentence_added": "{actor} added the {property} <i>{propertyNewValue}</i> to <b>{target}</b>.",
        "sentence_changed": "{actor} changed the {property} of <b>{target}</b> from <i>{propertyOldValue}</i> to <i>{propertyNewValue}</i>.",
        "sentence_removed": "{actor} removed the {property} <i>{propertyOldValue}</i> from <b>{target}</b>."
    },
    "el": {
        "sentence_added": "Ο χρήστης {actor} πρόσθεσε το {property} <i>{propertyNewValue}</i> σε <b>{target}</b>.",
        "sentence_changed": "Ο χρήστης {actor} άλλαξε το {property} σε <b>{target}</b> από <i>{propertyOldValue}</i> σε <i>{propertyNewValue}</i>.",
        "sentence_removed": "Ο χρήστης {actor} αφαίρεσε το {property} <i>{propertyOldValue}</i> από <b>{target}</b>."
    }
}
</i18n>
